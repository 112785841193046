import { saveAs } from 'file-saver';
import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { loadMoreDataWhenScrolled, parseHeaderForLinks } from 'react-jhipster';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ILoanProfile, defaultValue } from 'app/shared/model/loan-profile.model';

const initialState: EntityState<ILoanProfile> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: [],
  reverseRepayentity: [],
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  selectedStatus: '',
};

const TermLoanScheduleUrl = `${SERVER_API_URL}core-banking/flender/term-loan/term-loan-schedule`;
const BreakMonthsUrl = `${SERVER_API_URL}core-banking/flender/term-loan/applyPaymentBreak`;
const dueDateEditUrl = `${SERVER_API_URL}core-banking/flender/term-loan/changeDueDate`;
const installmentUrl = `${SERVER_API_URL}core-banking/flender/term-loan/changeInstalmentAmount`;
const updateFeeDetailsUrl = `${SERVER_API_URL}core-banking/flender/term-loan/applyFee`;
const updateTotalPaidUrl = `${SERVER_API_URL}core-banking/flender/term-loan/apply/manualRepayment`;
const termloanExcelUrl = `${SERVER_API_URL}core-banking/flender/term-loan/generate/term-loan-schedule-excel`;
const ReverseRepaymentList = `${SERVER_API_URL}core-banking/flender/term-loan/lastRepayment`;
const ApplyReverseRepaymentUrl = `${SERVER_API_URL}core-banking/flender/term-loan/reverseRepayment`;
const updateServicingFeeUrl = `${SERVER_API_URL}core-banking/flender/term-loan/updateServicingFee`;

export const getTermloanScheduleEntity = createAsyncThunk(
  'termloanSchedule/fetch_entity',
  async (loanId: any) => {
    const requestUrl = `${TermLoanScheduleUrl}?loanId=${loanId}`;
    const response = await axios.get<ILoanProfile>(requestUrl);
    return response.data;
  },
  { serializeError: serializeAxiosError },
);
export const getReverseRepaymentList = createAsyncThunk(
  'termloanSchedule/fetchrepayment_entity',
  async (loanId: any) => {
    const requestUrl = `${ReverseRepaymentList}?loanId=${loanId}`;
    const response = await axios.get<ILoanProfile>(requestUrl);
    return response.data;
  },
  { serializeError: serializeAxiosError },
);

export const addBreakMonths = createAsyncThunk(
  'termloanSchedule/addBreakMonths',
  async ({ loanId, instalment_number, breakPeriod }: ILoanProfile) => {
    try {
      const requestUrl = BreakMonthsUrl;
      const response = await axios.put<ILoanProfile[]>(requestUrl, {
        loan_id: loanId,
        instalment_number,
        break_period: breakPeriod,
      });
      return response.data;
    } catch (error) {
      throw serializeAxiosError(error);
    }
  },
);

export const editDueDate = createAsyncThunk(
  'termloanSchedule/edit_due_date',
  async ({ loanId, instalmentNumber, newDueDate }: ILoanProfile) => {
    try {
      const requestUrl = `${dueDateEditUrl}`;
      const response = await axios.put<ILoanProfile[]>(requestUrl, {
        loan_id: loanId,
        instalment_number: instalmentNumber,
        new_due_date: newDueDate,
      });
      return response.data;
    } catch (error) {
      throw serializeAxiosError(error);
    }
  },
);
export const getTermLoanScheduleExcelReport = createAsyncThunk('termLoan/fetch_excel_report', async ({ loanId }: ILoanProfile) => {
  try {
    const requestUrl = `${termloanExcelUrl}?loanId=${encodeURIComponent(loanId)}`;
    const response = await axios.get(requestUrl, {
      responseType: 'blob',
    });

    const contentDisposition = response.headers['content-disposition'];
    const filename = contentDisposition ? contentDisposition.split('filename=')[1]?.split(';')[0]?.trim() : `termloan_${loanId}.xlsx`;

    saveAs(response.data, filename);

    return { data: response.data, headers: response.headers };
  } catch (error) {
    console.error('Error downloading term loan Excel:', error.message);
  }
});

export const editInstallmentAmount = createAsyncThunk(
  'termloanSchedule/edit_installment_amount',
  async (payload: { data: { loan_id: string; instalment_number: number; new_instalment_amount: number } }) => {
    try {
      const requestUrl = `${installmentUrl}`;
      const response = await axios.put<ILoanProfile[]>(requestUrl, payload.data);
      return response.data;
    } catch (error) {
      throw serializeAxiosError(error);
    }
  },
);
export const updateFee = createAsyncThunk(
  'termloanSchedule/update_fee',
  async ({
    loanId,
    installment,
    newFeeAmount,
    feeType,
  }: {
    loanId: string;
    installment: number;
    newFeeAmount: number;
    feeType: string;
  }) => {
    const payload = {
      loan_id: loanId,
      installment,
      fee_amount: newFeeAmount,
      fee_type: feeType,
    };

    try {
      const response = await axios.post(updateFeeDetailsUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      throw serializeAxiosError(error);
    }
  },
);

export const updateServicingFee = createAsyncThunk(
  'termloanSchedule/update_servicing_fee',
  async ({ loanId, installment, fee_amount, feeType }: { loanId: string; installment: number; fee_amount: number; feeType: string }) => {
    const payload = {
      loan_id: loanId,
      installment,
      fee_amount: fee_amount,
      fee_type: feeType,
    };

    try {
      const response = await axios.post(updateServicingFeeUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      throw serializeAxiosError(error);
    }
  },
);

export const editTotalPaid = createAsyncThunk(
  'termloanSchedule/edit_total_paid',
  async ({
    loanId,
    instalmentNumber,
    amount,
    postingDate,
    postingNote,
  }: {
    loanId: string;
    instalmentNumber: number;
    amount: number;
    postingDate: string;
    postingNote: string;
  }) => {
    const requestUrl = `${updateTotalPaidUrl}?loanId=${encodeURIComponent(
      loanId,
    )}&instalmentNumber=${instalmentNumber}&amount=${amount}&postingDate=${postingDate}&postingNote=${postingNote}`;
    try {
      const response = await axios.post<ILoanProfile[]>(requestUrl);
      return response.data;
    } catch (error) {
      throw serializeAxiosError(error);
    }
  },
  { serializeError: serializeAxiosError },
);

export const applyReverseRepayment = createAsyncThunk(
  'termloanSchedule/apply_reverse_repayment',
  async ({ loanId, instalmentNumber, amount }: { loanId: string; instalmentNumber: number; amount: number }) => {
    const requestUrl = `${ApplyReverseRepaymentUrl}?loanId=${encodeURIComponent(
      loanId,
    )}&instalmentNumber=${instalmentNumber}&amount=${amount}`;

    try {
      const response = await axios.post<ILoanProfile[]>(requestUrl);
      return response.data;
    } catch (error) {
      throw serializeAxiosError(error);
    }
  },
  { serializeError: serializeAxiosError },
);

export const TermloanScheduleSlice = createEntitySlice({
  name: 'termloanSchedule',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getTermloanScheduleEntity.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getTermloanScheduleEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload;
      })
      .addCase(getTermloanScheduleEntity.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(getReverseRepaymentList.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getReverseRepaymentList.fulfilled, (state, action) => {
        console.log('Payload:', action.payload);
        1;
        state.loading = false;
        state.reverseRepayentity = action.payload;
      })
      .addCase(getReverseRepaymentList.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(addBreakMonths.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(addBreakMonths.fulfilled, (state, action) => {
        state.loading = false;
        state.breakMonths = action.payload;
      })
      .addCase(addBreakMonths.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(editDueDate.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })

      .addCase(editDueDate.fulfilled, (state, action) => {
        state.loading = false;
        state.editDueDate = action.payload;
      })
      .addCase(editDueDate.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(editInstallmentAmount.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })

      .addCase(editInstallmentAmount.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(updateFee.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(updateFee.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess = true;
        state.editFeeDetails = action.payload;
      })
      .addCase(updateFee.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(editTotalPaid.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(editTotalPaid.fulfilled, (state, action) => {
        state.loading = false;
        state.editedTotalPaid = action.payload;
      })
      .addCase(editTotalPaid.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(getTermLoanScheduleExcelReport.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(getTermLoanScheduleExcelReport.fulfilled, (state, action) => {
        state.loading = false;
        state.editedTotalPaid = action.payload;
      })
      .addCase(getTermLoanScheduleExcelReport.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(applyReverseRepayment.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(applyReverseRepayment.fulfilled, (state, action) => {
        state.loading = false;
        state.reversedRepayment = action.payload;
      })
      .addCase(applyReverseRepayment.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(updateServicingFee.pending, state => {
        state.loading = true;
        state.errorMessage = null;
      })
      .addCase(updateServicingFee.fulfilled, (state, action) => {
        state.loading = false;
        state.updatedServicingFee = action.payload;
      })
      .addCase(updateServicingFee.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const { reset } = TermloanScheduleSlice.actions;

export default TermloanScheduleSlice.reducer;

import React, { useState, useEffect } from 'react';
import { Table, Label } from 'reactstrap';
import { useAppDispatch } from 'app/config/store';
import { getEntities } from './loan-status.reducer';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import Pagination from 'react-bootstrap/Pagination';

require('jspdf-autotable');

export const LoanStatus = () => {
  const dispatch = useAppDispatch();
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [loanList, setLoanList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const index = currentPage - 1;
    dispatch(getEntities({ index, count: ITEMS_PER_PAGE, searchStatus: selectedStatus })).then((response: any) => {
      setLoanList(response?.payload?.data?.content);
      setTotalPages(response?.payload?.data?.totalPages);
    });
  }, [selectedStatus, currentPage, dispatch]);

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(e.target.value);
    setCurrentPage(1);
  };

  const handlePageClick = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      dispatch(getEntities({ index: (pageNumber - 1), count: ITEMS_PER_PAGE, searchStatus: selectedStatus })).then((response: any) => {
        setLoanList(response.payload.data.content);
      });
    }
  };


  return (
    <div className="pt-3">
      <h2 id="general-ledger-heading" data-cy="GeneralLedgerHeading">
        Loan Status
      </h2>
      <div className="col">
        <div className='max-div-search px-1'>
          <label className="custom-label">Select Loan Status:</label>
          <select
            name="loanStatus"
            id="loanStatusSelect"
            value={selectedStatus}
            onChange={handleStatusChange}
            className='custom-input-box'
          >
            <option value="">All</option>
            <option value="COMPLETED">COMPLETED</option>
            <option value="PENDING">PENDING</option>
            <option value="ACTIVE">ACTIVE</option>
          </select>
        </div>
      </div>
      <div className="col-md-12 pt-3">
        {loanList ? (
          <div className="table-responsive ">
            <Table responsive className="table-striped table-bordered tableContainer">
              <thead>
                <tr>
                  <th scope="col">Borrower Name</th>
                  <th scope="col" >Loan ID</th>
                  <th scope="col" >Status</th>
                </tr>
              </thead>
              <tbody>
                {loanList.map((loan: any, index: number) => (
                  <tr key={index}>
                    <td>{loan.borrower_name}</td>
                    <td>{loan.loan_id}</td>
                    <td>{loan.loanStatus}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : <div className="alert alert-warning showContainer mt-5">No Loans found</div>
        }
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Pagination>
          <Pagination.First onClick={() => handlePageClick(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageClick(currentPage - 1)} disabled={currentPage === 1} />
          {Array.from({ length: totalPages }, (_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => handlePageClick(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => handlePageClick(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageClick(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
      </div>
    </div>
  );
};

export default LoanStatus;

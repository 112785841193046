import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Button, Table } from 'reactstrap';
import { getPaginationState } from 'react-jhipster';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getBorrowerDetails, reset } from './borrower.reducer';
import Pagination from 'react-bootstrap/Pagination';

export const Borrower = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pageLocation = useLocation();
  const borrowerDetailsEntities = useAppSelector(state => state?.borrower?.borrowerDetails);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const loading = useAppSelector(state => state.borrower.loading);
  const updateSuccess = useAppSelector(state => state.borrower.updateSuccess);
  const totalPages = borrowerDetailsEntities?.data?.totalPages;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedBorrower, setSelectedBorrower] = useState(null);
  const [searchModified, setSearchModified] = useState(false);

  const getAllEntities = () => {
    dispatch(
      getBorrowerDetails({
        index: currentPage - 1,
        count: paginationState.itemsPerPage,
        searchKeyword: searchKeyword ? searchKeyword : '',
      }),
    );
  };

  const handlePageClick = pageNumber => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setPaginationState({
        ...paginationState,
        activePage: pageNumber,
      });
      setSearchKeyword('');
    }
  };

  const resetAll = () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getBorrowerDetails({}));
  };
  useEffect(() => {
    if (updateSuccess) {
      resetAll();
    }
  }, [updateSuccess]);

  useEffect(() => {
    getAllEntities();
  }, [currentPage]);


  const handleSearchResultSelect = (borrower) => {
    setSelectedBorrower(borrower);
    setSearchKeyword('');
  };

  useEffect(() => {
    getAllEntities();
  }, [currentPage]);

  const renderSearchResults = () => {
    if (searchKeyword && borrowerDetailsEntities?.data?.content?.length > 0) {
      return (
        <div className='searchResultsContainer'>
          {borrowerDetailsEntities?.data?.content
            .filter(borrower =>
              borrower.borrowerBusinessName.toLowerCase().includes(searchKeyword.toLowerCase()))
            .map((borrower, index) => (
              <div key={index} className="search-result" onClick={() => handleSearchResultSelect(borrower)}>
                {borrower.borrowerBusinessName}
              </div>
            ))}
        </div>
      );
    }
    else
      return null;
  };

  const handleSearchChange = (e) => {
    setSearchKeyword(e.target.value);
    setSearchModified(true);
  };

  const handleSearchClear = () => {
    setSelectedBorrower('');
    setSearchKeyword('');
    setSearchModified(false);
    getAllEntities();
  };

  const renderBorrowerTable = () => {
    return (
      <Table responsive className="table-striped">
        <thead>
          <tr>
            <th>Id</th>
            <th>Borrower Unique Id</th>
            <th>Borrower Business Name</th>
            <th>Borrower Business Cro Number</th>
            <th>Borrower Business Email</th>
            <th>Borrower Business Represent Person Name</th>
          </tr>
        </thead>
        <tbody>
          {selectedBorrower ? (
            <tr>
              <td>{selectedBorrower.id}</td>
              <td>
                <Button
                  tag={Link}
                  to={`/loan-details/${selectedBorrower.borrowerUniqueId}`}
                  color="link"
                  size="sm"
                  data-cy="entityDetailsButton"
                >
                  {selectedBorrower.borrowerUniqueId}
                </Button>
              </td>
              <td>{selectedBorrower.borrowerBusinessName}</td>
              <td>{selectedBorrower.borrowerBusinessCroNumber}</td>
              <td>{selectedBorrower.borrowerBusinessEmail}</td>
              <td>{selectedBorrower.borrowerBusinessRepresentPersonName}</td>
            </tr>
          ) : (
            borrowerDetailsEntities?.data?.content
              .map((borrower, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>{borrower.id}</td>
                  <td>
                    <Button
                      tag={Link}
                      to={`/loan-details/${borrower.borrowerUniqueId}`}
                      color="link"
                      size="sm"
                      data-cy="entityDetailsButton"
                    >
                      {borrower.borrowerUniqueId}
                    </Button>
                  </td>
                  <td>{borrower.borrowerBusinessName}</td>
                  <td>{borrower.borrowerBusinessCroNumber}</td>
                  <td>{borrower.borrowerBusinessEmail}</td>
                  <td>{borrower.borrowerBusinessRepresentPersonName}</td>
                </tr>
              ))
          )}
        </tbody>
      </Table>
    );
  };
  let searchValue;
  if (searchModified) {
    searchValue = searchKeyword;
  } else if (selectedBorrower) {
    searchValue = selectedBorrower.borrowerBusinessName;
  } else {
    searchValue = searchKeyword;
  }
  return (
    <>
      {/* <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <a
              onClick={() => {
                navigate('/');
              }}
            >
              Home
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem active> Borrower List</BreadcrumbItem>
        </Breadcrumb>
      </div> */}
      <div id="borrower-list-section">
        <h2 id="borrower-heading" data-cy="BorrowerHeading">
          Borrower List
          <div className="d-flex justify-content-end position-relative">
            <input
              type='search'
              placeholder="Search..."
              className='custom-input-box search-suggestion-input'
              value={searchValue}
              onChange={handleSearchChange}
              onBlur={() => setSearchModified(false)}
            />
            <div className="px-3">
              <Button className="search-content-btn" color="info" onClick={handleSearchClear}>
                Clear
              </Button>
            </div>
            {renderSearchResults()}
          </div>
        </h2>
        <div className="table-responsive">
          {/* <div className="tableContainer"> */}
          {borrowerDetailsEntities && borrowerDetailsEntities?.data?.content?.length > 0 ? (
            <>
              {renderBorrowerTable()}
            </>
          ) : (
            !loading && <div className="alert alert-warning">No Borrowers found</div>
          )}
          <div className="d-flex justify-content-end mt-3">
            <Pagination>
              <Pagination.First onClick={() => handlePageClick(1)} disabled={currentPage === 1} />
              <Pagination.Prev onClick={() => handlePageClick(currentPage - 1)} disabled={currentPage === 1} />
              {Array.from({ length: totalPages }, (_, index) => (
                <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageClick(index + 1)}>
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next onClick={() => handlePageClick(currentPage + 1)} disabled={currentPage === totalPages} />
              <Pagination.Last onClick={() => handlePageClick(totalPages)} disabled={currentPage === totalPages} />
            </Pagination>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default Borrower;

import './home.scss';
import React, { useCallback, useState } from 'react';
import { Row, Col, Button, NavItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import GeneralLedger from 'app/entities/general-ledger/general-ledger';
import LoanRepayment from 'app/entities/loan-repayment/loan-repayment';
import LoanProfile from 'app/entities/loan-profile/loan-profile';
import LoanFunder from 'app/entities/loan-funder/loan-funder';
import LoanStatus from 'app/entities/loan-status/loan-status';
import Reporting from 'app/entities/transaction-posting/transaction-posting';
import Borrower from 'app/entities/borrower/borrower';
import { useAppDispatch } from 'app/config/store';
import { fetchUrl } from './home.reducer';
import TermLoanProfile from 'app/entities/term-loan-profile/term-loan-profile';
import TermLoanSchedule from 'app/entities/term-loan-schedule/term-loan-schedule';
import Loan from 'app/entities/loan/loan';
import LenderInvestment from 'app/entities/lender-investment/lender-investment';
import TermLoanBook from 'app/entities/jasper-reports/termLoanBook';
import ServicingFeeReport from 'app/entities/jasper-reports/servicingFeeReport';
import TermLoanGeneralLedger from 'app/entities/jasper-reports/termLoanGeneralLedger';
import LoanRepaymentHistory from 'app/entities/jasper-reports/loanRepaymentHistory';
import LoanScheduleExcel from 'app/entities/jasper-reports/loanScheduleExcel';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAnglesLeft, faAnglesRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import AuditReport from 'app/entities/jasper-reports/auditReport';

export const Home = () => {
  const [activeMenu, setActiveMenu] = useState('RBLLoan');
  const [selectedContent, setSelectedContent] = useState('GeneralLedger');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const dispatch = useAppDispatch();

  const handleMenuClick = menu => {
    setActiveMenu(menu);
    if (menu === 'TermLoan') {
      setSelectedContent('TermLoanProfile');
    } else if (menu === 'RBLLoan') {
      setSelectedContent('GeneralLedger');
    } else if (menu === 'LenderInvestment') {
      setSelectedContent('LenderInvestment');
    } else if (menu === 'TermLoanBook') {
      setSelectedContent('TermLoanBook');
    } else if (menu === 'ServicingFeeReport') {
      setSelectedContent('ServicingFeeReport');
    } else if (menu === 'TermLoanGeneralLedger') {
      setSelectedContent('TermLoanGeneralLedger');
    } else if (menu === 'LoanRepaymentHistory') {
      setSelectedContent('LoanRepaymentHistory');
    } else if (menu === 'LoanScheduleExcel') {
      setSelectedContent('LoanScheduleExcel');
    } else if (menu === 'AuditReport') {
      setSelectedContent('AuditReport');
    }
  };

  const handleNfUserTokenButtonClick = useCallback(async () => {
    try {
      const action = await dispatch(fetchUrl());
      const response = action.payload;
      window.open(response);
    } catch (error) {
      console.error('Error fetching URL:', error);
    }
  }, [dispatch]);

  const handleContentClick = content => {
    setSelectedContent(content);
  };

  const renderRBLLoanMenu = () => (
    <div className={`flurish-sidebar ${isSidebarOpen ? 'open' : 'collapsed'}`}>
      <div
        onClick={() => handleContentClick('GeneralLedger')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'GeneralLedger' ? 'active' : ''}`}
        title="General Ledger"
      >
        <FontAwesomeIcon icon="file-lines" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">General Ledger</span>
      </div>

      <div
        onClick={() => handleContentClick('RepaymentReports')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'RepaymentReports' ? 'active' : ''}`}
        title="Repayment Reports"
      >
        <FontAwesomeIcon icon="tasks" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Repayment Reports</span>
      </div>

      <div
        onClick={() => handleContentClick('LoanProfile')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'LoanProfile' ? 'active' : ''}`}
        title="Loan Profile"
      >
        <FontAwesomeIcon icon="chalkboard" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Loan Profile</span>
      </div>

      <div
        onClick={() => handleContentClick('LenderProfile')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'LenderProfile' ? 'active' : ''}`}
        title="Lender Profile"
      >
        <FontAwesomeIcon icon="wallet" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Lender Profile</span>
      </div>

      <div
        onClick={() => handleContentClick('LoanStatus')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'LoanStatus' ? 'active' : ''}`}
        title="Loan Status"
      >
        <FontAwesomeIcon icon="bars-progress" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Loan Status</span>
      </div>

      <div
        onClick={() => handleContentClick('LoanReports')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'LoanReports' ? 'active' : ''}`}
        title="Loan Reports"
      >
        <FontAwesomeIcon icon="th-list" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Loan Reports</span>
      </div>

      <div
        onClick={() => handleContentClick('ManualLedgerPosting')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'ManualLedgerPosting' ? 'active' : ''}`}
        title="Manual Ledger Posting"
      >
        <FontAwesomeIcon icon="file-export" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Manual Ledger Posting</span>
      </div>

      <div
        onClick={() => handleContentClick('BorrowerList')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'BorrowerList' ? 'active' : ''}`}
        title="Borrower List"
      >
        <FontAwesomeIcon icon="users-line" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Borrower List</span>
      </div>

      <div onClick={() => handleNfUserTokenButtonClick()} className="nf-token-btn d-flex">
        <FontAwesomeIcon icon="sign-in-alt" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">NF User Token Login / Update</span>
      </div>
    </div>
  );

  const renderTermLoanMenu = () => (
    <div className={`flurish-sidebar ${isSidebarOpen ? 'open' : 'collapsed'}`}>
      <div
        onClick={() => handleContentClick('TermLoanProfile')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'TermLoanProfile' ? 'active' : ''}`}
        title="Term Loan Profile"
      >
        <FontAwesomeIcon icon="chalkboard" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Term Loan Profile</span>
      </div>

      <div
        onClick={() => handleContentClick('TermLoanSchedule')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'TermLoanSchedule' ? 'active' : ''}`}
        title="Term Loan Schedule"
      >
        <FontAwesomeIcon icon="tasks" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Term Loan Schedule</span>
      </div>
      {/* <div
        onClick={() => handleContentClick('TermLoanManualRepayment')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'TermLoanManualRepayment' ? 'active' : ''}`}
        title="Term Loan Manual Repayment"
      >
        <FontAwesomeIcon icon="file-export" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Manual Repayment</span>
      </div> */}
      <div
        onClick={() => handleContentClick('TermLoanGeneralLedger')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'TermLoanGeneralLedger' ? 'active' : ''}`}
        title="Term Loan Book"
      >
        <FontAwesomeIcon icon="file-lines" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">General Ledger</span>
      </div>
      {/* <div
        onClick={() => handleContentClick('LoanScheduleExcel')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'LoanScheduleExcel' ? 'active' : ''}`}
        title="LoanScheduleExcel"
      >
        <FontAwesomeIcon icon="file-lines" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Loan Schedule</span>
      </div> */}
      <div
        onClick={() => handleContentClick('TermLoanBook')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'TermLoanBook' ? 'active' : ''}`}
        title="Term Loan Book"
      >
        <FontAwesomeIcon icon="address-card" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Term Loan Book</span>
      </div>
      <div
        onClick={() => handleContentClick('ServicingFeeReport')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'ServicingFeeReport' ? 'active' : ''}`}
        title="Servicing Fee Report"
      >
        <FontAwesomeIcon icon="id-card" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Servicing Fee Report</span>
      </div>
      <div
        onClick={() => handleContentClick('LoanRepaymentHistory')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'LoanRepaymentHistory' ? 'active' : ''}`}
        title="Loan Repayment History"
      >
        <FontAwesomeIcon icon="id-card" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Loan Repayment History</span>
      </div>
      <div
        onClick={() => handleContentClick('AuditReport')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'AuditReport' ? 'active' : ''}`}
        title="Audit Report"
      >
        <FontAwesomeIcon icon="id-card" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Audit Report</span>
      </div>
    </div>
  );

  const renderLenderInvestmentMenu = () => (
    <div className={`flurish-sidebar ${isSidebarOpen ? 'open' : 'collapsed'}`}>
      <div
        onClick={() => handleContentClick('LenderInvestment')}
        className={`menu-item d-flex align-items-center ${selectedContent === 'LenderInvestment' ? 'active' : ''}`}
        title="Lender Investment"
      >
        <FontAwesomeIcon icon="user" className="sideBarCustomColor" />
        <span className="sideBarCustomColor">Lender Investment</span>
      </div>
    </div>
  );

  const renderContent = () => {
    if (selectedContent === 'GeneralLedger') return <GeneralLedger />;
    if (selectedContent === 'RepaymentReports') return <LoanRepayment />;
    if (selectedContent === 'LoanProfile') return <LoanProfile />;
    if (selectedContent === 'LenderProfile') return <LoanFunder />;
    if (selectedContent === 'LoanStatus') return <LoanStatus />;
    if (selectedContent === 'LoanReports') return <Loan />;
    if (selectedContent === 'ManualLedgerPosting') return <Reporting />;
    if (selectedContent === 'BorrowerList') return <Borrower />;
    if (selectedContent === 'TermLoanProfile')
      return (
        <div>
          <TermLoanProfile />
        </div>
      );
    if (selectedContent === 'TermLoanSchedule') return <TermLoanSchedule />;
    if (selectedContent === 'LenderInvestment')
      return (
        <LenderInvestment />
      );
    if (selectedContent === 'TermLoanBook') return <TermLoanBook />;
    if (selectedContent === 'ServicingFeeReport') return <ServicingFeeReport />;
    if (selectedContent === 'TermLoanGeneralLedger')
      return (
        <div>
          <TermLoanGeneralLedger />
        </div>
      );
    if (selectedContent === 'LoanScheduleExcel')
      return (
        <div>
          <LoanScheduleExcel />
        </div>
      );
    // if (selectedContent === 'TermLoanManualRepayment')
    //   return (
    //     <div>
    //       <TermLoanManualRepayment />
    //     </div>
    //   );
    if (selectedContent === 'LoanRepaymentHistory')
      return (
        <div>
          <LoanRepaymentHistory />
        </div>
      );
    if (selectedContent === 'AuditReport')
      return (
        <div>
          <AuditReport />
        </div>
      );
    return <div> </div>;
  };

  const [selectedHamburger, setSelectedHamburger] = useState(false);

  const handleSetActiveHamburger = () => {
    setSelectedHamburger(!selectedHamburger);
  };
  return (
    <Row className="max-vw-100">
      <div className={`col-auto flurish-left-sidebar sidebar-container ${selectedHamburger ? 'collapsed' : 'open'}`}>
        <div className="text-end pb-3">
          <button onClick={handleSetActiveHamburger} className={`btn-hamburger ${selectedHamburger && 'rotate-hamburger'}`}>
            <FontAwesomeIcon icon={faAnglesLeft} />
          </button>
        </div>
        <div className="pe-3 side-bar-menu-items">
          {activeMenu === 'RBLLoan' && renderRBLLoanMenu()}
          {activeMenu === 'TermLoan' && renderTermLoanMenu()}
          {activeMenu === 'LenderInvestment' && renderLenderInvestmentMenu()}
        </div>
      </div>
      <div className="col content-area">
        <div className="col content-area-top-bar d-flex px-4">
          <div>
            <Button
              className={`top-bar-custom-Button ${activeMenu === 'RBLLoan' ? 'top-bar-btn-active' : ''}`}
              onClick={() => handleMenuClick('RBLLoan')}
            >
              RBL Loan
            </Button>
          </div>
          <div>
            <Button
              className={`top-bar-custom-Button ${activeMenu === 'TermLoan' ? 'top-bar-btn-active' : ''}`}
              onClick={() => handleMenuClick('TermLoan')}
            >
              Term Loan
            </Button>
          </div>
          <div>
            <Button
              className={`top-bar-custom-Button ${activeMenu === 'LenderInvestment' ? 'top-bar-btn-active' : ''}`}
              onClick={() => handleMenuClick('LenderInvestment')}
            >
              Lender Investment
            </Button>
          </div>
        </div>
        <div className="px-4 py-3">{renderContent()}</div>
      </div>
    </Row>
  );
};

export default Home;

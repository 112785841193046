import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Table } from 'reactstrap';
import { TextFormat, getPaginationState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faFileExcel, faFilePdf, faFolderBlank, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { getEntities, getExcelReport, getPdfReport, reset } from './general-ledger.reducer';
import Pagination from 'react-bootstrap/Pagination';
import NumberFormat from 'react-number-format';

export const GeneralLedger = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const [paginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const generalLedgerList = useAppSelector(state => state?.generalLedger?.entities);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = ITEMS_PER_PAGE;
  const loading = useAppSelector(state => state?.generalLedger?.loading);
  const updateSuccess = useAppSelector(state => state?.generalLedger?.updateSuccess);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const totalItems = generalLedgerList?.totalItems;
  const totalPages = generalLedgerList?.totalPages;



  const handleStartDateChange = event => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = event => {
    setEndDate(event.target.value);
  };
  const MAX_VISIBLE_PAGES = 5;
  const renderPaginationItems = () => {
    const items = [];
    const startPage = Math.max(1, currentPage - Math.floor(MAX_VISIBLE_PAGES / 2));
    const endPage = Math.min(totalPages, startPage + MAX_VISIBLE_PAGES - 1);

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    return items;
  };
  const renderPagination = () => {
    if (totalPages <= 1) return null;

    return (
      <Pagination>
        <Pagination.First onClick={() => handlePageClick(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handlePageClick(currentPage - 1)} disabled={currentPage === 1} />
        {renderPaginationItems()}
        <Pagination.Next onClick={() => handlePageClick(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => handlePageClick(totalPages)} disabled={currentPage === totalPages} />
      </Pagination>
    );
  };
  useEffect(() => {
    resetAll();
  }, [pageLocation]);

  const handleViewList = () => {
    const newIndex = paginationState.activePage > 0 ? (paginationState.activePage - 1) : 0;
    dispatch(
      getEntities({
        startDate,
        endDate,
        index: newIndex,
        count: ITEMS_PER_PAGE,
      }),
    );
    dispatch(
      getEntities({
        page: currentPage - 1,
        size: itemsPerPage,
        startDate,
        endDate,
        index: newIndex,
        count: ITEMS_PER_PAGE,
      }),
    );
  };



  const handleDownloadExcel = () => {
    dispatch(getExcelReport());
  };

  // const [loadingL, setLoading] = useState(false);


  // const handleDownloadExcel = async () => {
  //   setLoading(true);
  //   try {
  //     await dispatch(getExcelReport()).unwrap();
  //     // alert("Excel report downloaded successfully!");
  //   } catch (error) {
  //     console.error("Failed to download Excel report:", error);
  //     // alert("There was an error downloading the report.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  const handleDownloadPdf = () => {
    dispatch(getPdfReport());
  };

  useEffect(() => {
    dispatch(
      getEntities({
        startDate,
        endDate,
        page: currentPage,
        size: ITEMS_PER_PAGE,
        index: (currentPage - 1) >= 0 ? (currentPage - 1) : 0,
        count: ITEMS_PER_PAGE,
      }),
    );
  }, [currentPage, startDate, endDate]);

  const resetAll = () => {
    dispatch(reset());
    setCurrentPage(1);
  };



  const handlePageClick = (page) => {
    setCurrentPage(page);
    const startIndex = (page - 1);
    dispatch(
      getEntities({
        startDate,
        endDate,
        page,
        size: itemsPerPage,
        index: startIndex,
        count: ITEMS_PER_PAGE,
      }),
    );
  };

  useEffect(() => {
    if (!isNaN(totalItems) && !isNaN(itemsPerPage)) {
      if (currentPage > totalPages) {
        setCurrentPage(totalPages);
      }
    }
  }, [totalItems, itemsPerPage]);

  useEffect(() => {
    resetAll();
  }, [startDate, endDate]);

  useEffect(() => {
    if (updateSuccess) {
      resetAll();
    }
  }, [updateSuccess]);


  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className='pt-3'>
      <h2 id="general-ledger-heading" data-cy="GeneralLedgerHeading">
        General Ledger
      </h2>

      {/* <div className="text-end">
        <Button className="me-2 generate-pdf-btn customButton" onClick={handleDownloadExcel} disabled={loading}>
          <FontAwesomeIcon icon={faBook} spin={loading} /> Generate Report
        </Button>
        <Button className="me-2 generate-pdf-btn customButton" onClick={() => handleDownloadPdf()} disabled={loading}>
          <FontAwesomeIcon icon={faBook} spin={loading} /> Generate PDF Report
        </Button>
      </div> */}
      <div className="row justify-content-between pt-2 ">
        <div className='col content-btn-div pt-1'>
          <div className='me-2 mt-2 mb-2'>
            <label className="">From :&nbsp;</label>
            <input type="date" value={startDate} onChange={handleStartDateChange} className="custom-input-box" />
          </div>
          <div className='me-2 mt-2 mb-2'>
            <label className="">To :&nbsp;</label>
            <input type="date" value={endDate} onChange={handleEndDateChange} className="custom-input-box" />
          </div>
          <Button className="me-2 search-content-btn" onClick={handleViewList} disabled={loading}>
            View List
          </Button>
        </div>
        <div className='col-auto text-end pt-1'>
          {/* <Button className="me-2 generate-report-btn mt-2" onClick={handleDownloadExcel} disabled={loading}>
            <FontAwesomeIcon icon={faFileExcel} spin={loading} /> Generate Report
            {loadingL ? "Downloading..." : "Download Excel"}
          </Button>

          <Button className="me-2 generate-report-btn mt-2" onClick={() => handleDownloadPdf()} disabled={loading}>
            <FontAwesomeIcon icon={faFilePdf} spin={loading} /> Generate PDF Report
          </Button> */}
          <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
            <DropdownToggle caret className='generate-report-btn'>Generate Report</DropdownToggle>
            <DropdownMenu className='custom-dropdown-menu'>
              <DropdownItem onClick={handleDownloadExcel}>
                <FontAwesomeIcon icon={faFileExcel} spin={loading} />
                &nbsp; As Excel
              </DropdownItem>
              <DropdownItem onClick={() => handleDownloadPdf()}>
                <FontAwesomeIcon icon={faFilePdf} spin={loading} />
                &nbsp; As PDF
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>

      <div className="table-responsive mainContainer pt-3">
        <div>
          {generalLedgerList?.content && generalLedgerList?.content.length > 0 ? (
            <Table responsive className="table-striped table-bordered" >
              <thead>
                <tr>
                  <th className="hand">Transaction Date</th>
                  <th className="hand">Entity Type</th>
                  <th className="hand">Narration</th>
                  <th className="hand">Account Number</th>
                  <th className="hand">Account Name</th>
                  <th className="hand">Loan Id</th>
                  <th className="hand">Transaction Reference Number</th>
                  <th className="hand">Credit</th>
                  <th className="hand">Debit</th>
                  <th className="hand">Currency</th>
                </tr>
              </thead>
              <tbody>
                {generalLedgerList?.content &&
                  generalLedgerList?.content?.map((generalLedger, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>
                        {generalLedger.txnDate ? (
                          <TextFormat type="date" value={generalLedger.txnDate} format={APP_DATE_FORMAT} />
                        ) : null}
                      </td>
                      <td>{generalLedger.entityType}</td>
                      <td>{generalLedger.narration2}</td>
                      <td>{generalLedger.accountNumber}</td>
                      <td>{generalLedger.accountName}</td>
                      <td>{generalLedger.loanId}</td>
                      <td>{generalLedger.txnRefNumber}</td>
                      <td>
                        <NumberFormat
                          value={generalLedger.credit}
                          thousandSeparator={true}
                          prefix={'€ '}
                          displayType={'text'}
                        />
                      </td>
                      <td>
                        <NumberFormat
                          value={generalLedger.debit}
                          thousandSeparator={true}
                          prefix={'€ '}
                          displayType={'text'}
                        />
                      </td>
                      <td>{generalLedger.currency}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className="showContainer text-center pt-4 ">
                <FontAwesomeIcon icon={faFolderOpen} />
                &nbsp;No Data found....
              </div>
            )
          )}
        </div>
      </div>
      <div className="d-flex justify-content-end mt-3">
        {renderPagination()}
      </div>
    </div>
  );
};

export default GeneralLedger;

import './header.scss';

import React, { useState, useEffect, useCallback } from 'react';
import LoadingBar from 'react-redux-loading-bar';
import { Home } from './header-components';
import { AdminMenu, EntitiesMenu, AccountMenu } from '../menus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { fetchUrl } from 'app/modules/home/home.reducer';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  isReadWriteUser: boolean;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
}

const Header = (props: IHeaderProps) => {
  const [isSidebarClosed, setIsSidebarClosed] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const role = useAppSelector(state => state.authentication.role) as string;
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);

  const [userType, setUserType] = useState('');

  useEffect(() => {
    const storedRole = localStorage.getItem('roles');
    if (storedRole) {
      setUserType(storedRole);
    }
  }, [role, isAuthenticated]);

  const handleSidebarToggle = () => {
    setIsSidebarClosed(!isSidebarClosed);
    const appViewContainer = document.getElementById('app-view-container');
    appViewContainer?.classList.toggle('view-container-active');
  };

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  // const dispatch = useAppDispatch();

  // const handleButtonClick = useCallback(async () => {
  //   try {
  //     const action = await dispatch(fetchUrl());
  //     const response = action.payload;
  //     window.open(response);
  //   } catch (error) {
  //     console.error('Error fetching URL:', error);
  //   }
  // }, [dispatch]);

  return (
    <>
      {/* <div className={`sidebar ${isSidebarClosed ? 'close' : ''}`}> */}
      {/* <LoadingBar className="loading-bar" /> */}
      {/* <div className="logo-details">
          <FontAwesomeIcon icon="bars" className="hamburger" onClick={handleSidebarToggle} />
        </div> */}
      {/* <ul id="header-tabs" className="nav-links">
          <Home isVisible={!isSidebarClosed} />
          {props.isAuthenticated && <EntitiesMenu isVisible={!isSidebarClosed} />}
          {props.isAuthenticated && props.isAdmin && <AdminMenu showOpenAPI={props.isOpenAPIEnabled} />}
          <AccountMenu isAuthenticated={props.isAuthenticated} isVisible={!isSidebarClosed} />
        </ul> */}
      {/* </div> */}
      <div className="app-header" id="app-header">
        <div data-cy="navbar" className={`navbar navbar-expand-md navbar-dark fixed-top ${isSidebarClosed ? 'navbar-active' : ''}`}>
          <div className="container-fluid d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="brand-logo navbar-brand ">
                <div className='d-flex'>
                  <img className="icon_logo" src="../../../../content/images/logo-jhipster.png" width={70} />
                  {/* <span className="brand-title text-dark ms-3 mt-2">Backoffice Application</span> */}
                </div>

              </div>
            </div>

            <div className="d-flex align-items-center">


              <ul className="navbar-nav align-items-center">
                <div className='d-flex align-items-center me-3 login-name'>
                  <FontAwesomeIcon icon="user-circle" size="2x" />
                  {props.isAuthenticated && (
                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="ms-2">
                      <DropdownToggle tag="div" caret>
                        {userType === '["ROLE_READWRITE"]' ? 'Admin' : 'User'}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem header>User Settings</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem tag={Link} to="/logout">
                          <FontAwesomeIcon icon="sign-out-alt" className='signOutCustomColor' />
                          <span className="signOutCustomColor ms-2">Sign out</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  )}
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

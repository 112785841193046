import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getTermloanListEntity } from '../term-loan-profile/term-loan-profile.reducer';
import { getBorrowerIdList } from '../loan-repayment/loan-repayment.reducer';
import { Button } from 'reactstrap';
import Select from 'react-select';
import { faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ServicingFeeReport = () => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [borrowerName, setBorrowerName] = useState<string>('');
  const [borrowerId, setBorrowerId] = useState<string>('');
  const [loanId, setLoanId] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [searchableLoanId, setSearchableLoanId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [viewClicked, setViewClicked] = useState(false);


  const dispatch = useAppDispatch();

  const borrowerNameList = useAppSelector(state => state.termLoanProfile?.termLoanBorrowers);
  const borrowerIdList = useAppSelector(state => state.loanRepayment?.borrowerIdList);
  const termLoanIdList = useAppSelector(state => state.termLoanProfile?.loanIdList);
  const termLoanFullIdList = useAppSelector(state => state.termLoanProfile?.termLoanIdListEntity);

  useEffect(() => {
    if (loanId) {
      fetchReport();
    }
  }, [loanId]);

  const handleBorrowerName = async event => {
    const selectedBorrowerName = event.target.value;
    setBorrowerName(selectedBorrowerName);
    setBorrowerId('');
    setLoanId('');
    setErrorMessage(null);
    try {
      await dispatch(getBorrowerIdList(selectedBorrowerName));
    } catch (error) {
      setErrorMessage('Error fetching borrower ID list');
    }
  };

  const handleBorrowerId = async event => {
    const selectedBorrowerId = event.target.value;
    setBorrowerId(selectedBorrowerId);
    setLoanId('');
    setErrorMessage(null);

    try {
      await dispatch(getTermloanListEntity(selectedBorrowerId));
    } catch (error) {
      setErrorMessage('Error fetching loan ID list');
    }
  };

  const handleLoanIdChange = event => {
    const selectedLoanId = event.target.value;
    setLoanId(selectedLoanId);
    setErrorMessage(null);
  };
  const downloadReportCSV = async () => {
    setLoading(true);
    if (!loanId) {
      setErrorMessage('Please select all fields before viewing the report.');
      return;
    }

    try {
      const response = await axios.get(`${REPORT_URL}/jasperserver/rest_v2/reports/jasper/Term_Loan_Book.csv`, {
        params: {
          Loan_Id: loanId,
        },
        auth: {
          username: 'jasperadmin',
          password: 'bitnami',
        },
        responseType: 'arraybuffer',
      });
      const file = new Blob([response.data], { type: 'text/csv' });
      const fileURL = URL.createObjectURL(file);

      const link = document.createElement('a');
      link.href = fileURL;
      link.download = `Term_Loan_Book_${loanId}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setErrorMessage(null);
    } catch (err) {
      console.error('Error fetching the report:', err);
      setErrorMessage('Failed to load report');
    }
    finally {
      setLoading(false);
    }
  };
  const fetchReport = async () => {
    if (!loanId) {
      setErrorMessage('Please select all fields before viewing the report.');
      return;
    }

    try {
      const response = await axios.get(`${REPORT_URL}/jasperserver/rest_v2/reports/jasper/Servicing_Fee_Report.pdf`, {
        params: {
          Loan_id: loanId,
        },
        auth: {
          username: 'jasperadmin',
          password: 'bitnami',
        },
        responseType: 'arraybuffer',
      });
      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      setPdfUrl(fileURL);
      setErrorMessage(null);
    } catch (err) {
      console.error('Error fetching the report:', err);
      setErrorMessage('Failed to load report');
    }
  };
  ///==================================
  const loanIdOptions = termLoanFullIdList?.map(loanId => ({
    value: loanId,
    label: loanId,
  }));
  const handleLoanIdSearch = selectedOption => {
    setLoanId(selectedOption?.value || '');
    setSearchableLoanId(selectedOption);
  };
  const handleInputChange = inputValue => {
    setSearchableLoanId(inputValue);
  };
  const customComponents = {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
  };

  const customFilterOption = (option, inputValue) => {
    if (!inputValue) return true;
    return option.label.toLowerCase().startsWith(inputValue.toLowerCase());
  };

  //=================================

  return (
    <div className='pt-3'>
      <h2>Servicing Fee Report</h2>
      <div className="row justify-content-between align-items-end dropDownContainer pb-3">
        <div className="col-auto content-btn-div">
          <div className="max-div-search px-1">
            <label className="custom-label">
              Borrower Name:
            </label>
            <select value={borrowerName} onChange={handleBorrowerName} className="custom-input-box">
              <option>Select Borrower</option>
              {borrowerNameList?.map((borrower, i) => (
                <option key={i} value={borrower.borrowerBusinessName}>
                  {borrower.borrowerBusinessName}
                </option>
              ))}
            </select>
          </div>
          <div className="max-div-search px-1">
            <label className="custom-label">
              Borrower ID:
            </label>
            <select value={borrowerId} onChange={handleBorrowerId} disabled={!borrowerName} className="custom-input-box">
              <option>Select Borrower ID</option>
              {borrowerIdList?.map((borrower_Id, index) => (
                <option key={index} value={borrower_Id}>
                  {borrower_Id}
                </option>
              ))}
            </select>
          </div>

          {borrowerId && (
            <>
              <div className="max-div-search px-1">
                <label className="custom-label">
                  Loan ID:
                </label>
                <select value={loanId} onChange={handleLoanIdChange} className="custom-input-box" disabled={!borrowerId}>
                  <option value="">Select Loan</option>
                  {termLoanIdList?.map((loanId, i) => (
                    <option key={i} value={loanId}>
                      {loanId}
                    </option>
                  ))}
                </select>
              </div>
              <div className="px-2">
                <Button className="search-content-btn" onClick={fetchReport}>
                  View
                </Button>
              </div>
            </>
          )}
          {!borrowerId && (
            <>
              <div className="max-div-search px-1">
                <Select
                  options={loanIdOptions}
                  value={searchableLoanId}
                  onChange={handleLoanIdSearch}
                  onInputChange={handleInputChange}
                  components={customComponents}
                  placeholder="Select or search Loan ID..."
                  className="react-select-container"
                  classNamePrefix="react-select"
                  filterOption={customFilterOption}
                />
              </div>
              <div className='max-div-search px-1'>
                <Button className="search-content-btn" onClick={fetchReport}>
                  Search
                </Button>
              </div>
            </>
          )}
        </div>
        {pdfUrl && (
          <div className="col text-end">
            <Button className="generate-report-btn" onClick={downloadReportCSV}>
              {loading ?
                <>
                  <FontAwesomeIcon icon={faSpinner} spin={loading} />
                  &nbsp;Preparing Report
                </> : <>
                  <FontAwesomeIcon icon={faDownload} />
                  &nbsp;Download CSV
                </>
              }
            </Button>
          </div>
        )}
      </div>

      {errorMessage && <div className="text-danger">{errorMessage}</div>}

      {pdfUrl && <iframe src={pdfUrl} title="Report Viewer" width="100%" height="600px"></iframe>}
    </div>
  );
};

export default ServicingFeeReport;

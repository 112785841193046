import React, { useEffect, useState } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Button,
  Col,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Input,

} from 'reactstrap';
import classnames from 'classnames';
import {
  createEntity,
  getHoldBackAndOutstandingPrincipalAmount,
  updateHoldBackPercentage,
  updatePrincipalAmount,
} from './transaction-posting.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ValidatedField } from 'react-jhipster';
import { useForm } from 'react-hook-form';
import { getBorrowerIdList, getLoanIdList } from '../loan-repayment/loan-repayment.reducer';
import { getEntities as getBorrowerUniqueID } from '../borrower/borrower.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';


const Reporting = () => {
  const dispatch = useAppDispatch();
  const [dropdownValue, setDropdownValue] = useState('');
  const [txnDate, setTxnDate] = useState('');
  const [toAccountNumber, setToAccountNumber] = useState('');
  const [fromAccountNumber, setFromAccountNumber] = useState('');
  const [lenderName, setLenderName] = useState('');
  const [amount, setAmount] = useState('');
  const [loanId, setLoanId] = useState('');
  const [borrowerName, setBorrowerName] = useState('');
  const [holdBackAndOutstandingPrincipalAmount, setHoldBackAndOutstandingPrincipalAmount] = useState<{
    principal_amount?: number;
    holdback_rate?: number;
    outstanding_interest?: number;
    outstanding_repayment_amount?: number;
  }>({});

  const borrowerNameList = useAppSelector(state => state.borrower.entities);
  const borrowerIdList = useAppSelector(state => state.loanRepayment.borrowerIdList);
  const loanIdList = useAppSelector(state => state.loanRepayment.loanList);
  const [activeTab, setActiveTab] = useState('loanRepayment');
  const [borrowerId, setBorrowerId] = useState('');
  const [outstandingAmount, setOutstandingAmount] = useState<number>(0);
  const [editedOutstandingAmount, setEditedOutstandingAmount] = useState<number>(0);
  const [editedHoldBackPercentage, setEditedHoldBackPercentage] = useState<number>(0);
  const [outstandingAmountEditMode, setOutstandingAmountEditMode] = useState(false);
  const [holdBackPercentageEditMode, setHoldBackPercentageEditMode] = useState(false);
  const { register, control } = useForm();


  const handleAccountEntry = e => {
    e.preventDefault();
    const entity = {
      lender_type: dropdownValue,
      from_account_number: fromAccountNumber,
      to_account_number: toAccountNumber,
      amount: parseFloat(amount),
      date: txnDate,
      lender_name: lenderName,
    };

    dispatch(createEntity(entity));
    setDropdownValue('');
    setTxnDate('');
    setToAccountNumber('');
    setFromAccountNumber('');
    setLenderName('');
    setAmount('');
  };

  const handleFilter = async () => {
    try {
      dispatch(getHoldBackAndOutstandingPrincipalAmount(loanId)).then((response: any) => {
        if (response.payload && response.payload.data) {
          setHoldBackAndOutstandingPrincipalAmount(response.payload.data);
          const principalAmount = response.payload.data.principal_amount;
          setOutstandingAmount(principalAmount);
        }
      });
    } catch (error) {
    }
  };

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleBorrowerName = async event => {
    const selectedBorrowerName = event.target.value;
    setBorrowerName(selectedBorrowerName);

    try {
      await dispatch(getBorrowerIdList(selectedBorrowerName));
    } catch (error) {
    }
  };

  const handleBorrowerId = async event => {
    const selectedBorrowerId = event.target.value;
    setBorrowerId(selectedBorrowerId);

    try {
      await dispatch(getLoanIdList({ borrowerId: selectedBorrowerId }));
    } catch (error) {
    }
  };

  const handleLoanId = async event => {
    setLoanId(event.target.value);
  };

  useEffect(() => {
    dispatch(getBorrowerUniqueID({}));
  }, []);


  useEffect(() => {
    setBorrowerName('');
    setBorrowerId('');
    setLoanId('');
  }, [activeTab]);

  useEffect(() => {
    setBorrowerId('');
    setLoanId('');
  }, [borrowerName]);

  const accountCreationHandleSubmit = async () => {

  };



  const handleOutstandingAmountEditClick = () => {
    setOutstandingAmountEditMode(true);
    setEditedOutstandingAmount(0);
  };

  const handleHoldBackPercentageEditClick = () => {
    setHoldBackPercentageEditMode(true);
    handleFilter();
    setEditedHoldBackPercentage(0);
  };

  const handleSubmitOutstandingAmount = async () => {
    try {
      await dispatch(updatePrincipalAmount({ loanId: loanId, outstandingAmount: editedOutstandingAmount }));
      setOutstandingAmount(editedOutstandingAmount);
      handleFilter();
      setOutstandingAmountEditMode(false);
    } catch (error) {
    }
  };

  const handleSubmitHoldBackPercentage = async () => {
    try {
      await dispatch(updateHoldBackPercentage({ loanId: loanId, holdBackPercentage: editedHoldBackPercentage }));
      setHoldBackPercentageEditMode(false);
      handleFilter();
    } catch (error) {
    }
  };

  const handleCloseClickOutstandingAmount = () => {
    setOutstandingAmountEditMode(false);
  };

  const handleCloseClickHoldBackPercentage = () => {
    setHoldBackPercentageEditMode(false);
  };

  return (
    <div>
      <h2>Manual Ledger Posting</h2>
      <Nav tabs className="mt-3">
        <NavItem>
          <NavLink
            // className={classnames({ active: activeTab === '1' })}
            className={`tab-custom-Button ${activeTab === 'loanRepayment' ? "tab-btn-active" : ""}`}
            onClick={() => toggleTab('loanRepayment')}
          // style={{
          //   color: activeTab === 'loanRepayment' ? '#ffffff' : 'black',
          //   backgroundColor: activeTab === 'loanRepayment' ? '#006494' : '#c0c0c0',
          // }}
          >
            Loan Repayment
          </NavLink>
        </NavItem>
        <NavItem className="ms-2">
          <NavLink
            // className={classnames({ active: activeTab === '2' })}
            className={`tab-custom-Button ${activeTab === '2' ? "tab-btn-active" : ""}`}
            onClick={() => toggleTab('2')}
          // style={{ color: activeTab === '2' ? '#ffffff' : 'black', backgroundColor: activeTab === '2' ? '#006494' : '#c0c0c0' }}
          >
            Non Ledger Entries
          </NavLink>
        </NavItem>
        <NavItem className="ms-2">
          <NavLink
            // className={classnames({ active: activeTab === '3' })}
            className={`tab-custom-Button ${activeTab === '3' ? "tab-btn-active" : ""}`}
            onClick={() => toggleTab('3')}
          // style={{ color: activeTab === '3' ? '#ffffff' : 'black', backgroundColor: activeTab === '3' ? '#006494' : '#c0c0c0' }}
          >
            Manual Account Creation
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="loanRepayment">
          <div className="row justify-content-between align-items-end pt-3">
            <div className="col content-btn-div">
              <div className="max-div-search px-1">
                <label className="custom-label">
                  Select Borrower Name :
                </label>
                <select value={borrowerName} onChange={handleBorrowerName} className='custom-input-box'>
                  <option>Select Borrower</option>
                  {borrowerNameList?.map((borrower, i) => (
                    <option key={i} value={borrower.borrowerBusinessName}>
                      {borrower.borrowerBusinessName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="max-div-search px-1">
                <label className="custom-label">
                  Select Borrower ID
                </label>
                <select value={borrowerId} onChange={handleBorrowerId} className='custom-input-box'>
                  <option>Select Borrower ID</option>
                  {borrowerIdList.map((borrower_Id, index) => (
                    <option key={index} value={borrower_Id}>
                      {borrower_Id}
                    </option>
                  ))}
                </select>
              </div>

              <div className="max-div-search px-1">
                <label className="custom-label">
                  Select Loan ID
                </label>
                <select value={loanId} onChange={handleLoanId} className='custom-input-box'>
                  <option value="">Select Loan</option>
                  {loanIdList.map((loan_Id, i) => (
                    <option key={i} value={loan_Id}>
                      {loan_Id}
                    </option>
                  ))}
                </select>
              </div>
              <div className="max-div-search px-1">
                <Button type="button" onClick={handleFilter} className='search-content-btn'>
                  View
                </Button>
              </div>
            </div>
          </div>
          {holdBackAndOutstandingPrincipalAmount.holdback_rate !== undefined && holdBackAndOutstandingPrincipalAmount.holdback_rate !== null && loanId !== '' && (
            <div className="col-12 pt-3">
              <div className="card  card-custom">
                <div className="card-body">
                  <div className="row pb-3">
                    <div className='col-md-4 pt-3'>
                      <div className='contend-heading'>Borrower Name:</div>
                      <div className='contend-value'>{borrowerName}</div>
                    </div>
                    <div className="col-md-4 pt-3">
                      <div className='contend-heading'>Borrower ID :</div>
                      <div className='contend-value'>{borrowerId}</div>
                    </div>
                    <div className="col-md-4 pt-3">
                      <div className='contend-heading'>Loan Id :</div>
                      <div className='contend-value'>{loanId}</div>
                    </div>

                    <div className="col-md-4 pt-3">
                      <div className='contend-heading'>Outstanding Repayment Amount:</div>
                      <div className='contend-value'>
                        <NumberFormat
                          value={holdBackAndOutstandingPrincipalAmount?.outstanding_repayment_amount}
                          thousandSeparator={true}
                          prefix={'€ '}
                          displayType={'text'}
                        />
                      </div>
                    </div>

                    <div className="col-md-4 pt-3">
                      <div className='contend-heading'>Current HoldBack Percentage:</div>
                      <div className='contend-value'>
                        <NumberFormat
                          value={holdBackAndOutstandingPrincipalAmount.holdback_rate}
                          thousandSeparator={true}
                          suffix={' %'}
                          displayType={'text'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {outstandingAmount !== null && outstandingAmount !== 0 && loanId !== '' && (
            <div className="row pb-5">
              <div className="col-12">
                <div className="sub-heading-custom pt-3 pb-3">
                  Update Details
                </div>
                <div className="card  card-custom">
                  <div className="card-body">
                    <div className="">
                      {outstandingAmountEditMode ? (
                        <div className="loan-repayment-text d-flex align-items-center">
                          <span>
                            Manual Repayment Amount:
                          </span>
                          <div className="px-2">
                            <input
                              type="number"
                              name="editedOutstandingAmount"
                              value={editedOutstandingAmount === 0 ? '' : editedOutstandingAmount}
                              onChange={e => {
                                const newValue = e.target.value;
                                setEditedOutstandingAmount(newValue === '' ? 0 : parseFloat(newValue));
                              }}
                              className="custom-input-box"
                            />
                          </div>
                          <div className="px-2">
                            <Button
                              className="search-content-btn"
                              onClick={handleSubmitOutstandingAmount}
                            >
                              Submit
                            </Button>
                          </div>
                          <div className="px-2">
                            <Button
                              className="close-btn"
                              onClick={handleCloseClickOutstandingAmount}
                            >
                              X
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className='loan-repayment-text'>
                          <span>
                            Manual Repayment Amount:
                          </span>
                          <span onClick={handleOutstandingAmountEditClick}>
                            <FontAwesomeIcon icon="pencil" />
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="pt-3">
                      {holdBackPercentageEditMode ? (
                        <div className='loan-repayment-text d-flex align-items-center'>
                          <span className="">
                            New HoldBack Percentage:
                          </span>
                          <div className="px-2">
                            <input
                              type="number"
                              name="editedHoldBackPercentage"
                              value={editedHoldBackPercentage === 0 ? '' : editedHoldBackPercentage}
                              onChange={e => setEditedHoldBackPercentage(parseFloat(e.target.value))}
                              className="custom-input-box"
                            />
                          </div>
                          <div className="px-2">
                            <Button
                              className="search-content-btn"
                              color="success"
                              onClick={handleSubmitHoldBackPercentage}
                            >
                              Submit
                            </Button>
                          </div>
                          <div className="px-2">
                            <Button
                              className="close-btn"
                              onClick={handleCloseClickHoldBackPercentage}
                            >
                              X
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className='loan-repayment-text'>
                          <span>
                            New HoldBack Percentage:
                          </span>
                          <span onClick={handleHoldBackPercentageEditClick}>
                            <FontAwesomeIcon icon="pencil" />
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </TabPane>
        <TabPane tabId="2">
          <div className='mainContainer'>
            <Form onSubmit={handleAccountEntry} className="pt-3 pb-5">
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className='custom-label'>
                      Lender Type :
                    </Label>
                    <select
                      name="dropdown"
                      id="dropdown"
                      className='custom-input-box'
                      value={dropdownValue}
                      onChange={e => setDropdownValue(e.target.value)}>
                      <option value="">Select an option</option>
                      <option value="Flender">Flurish</option>
                      <option value="Funder">Funder</option>
                    </select>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="lenderName" className='custom-label'>Name : </Label><br />
                    <Input
                      type="text"
                      name="lenderName"
                      id="lenderName"
                      className='custom-input-box'
                      value={lenderName}
                      onChange={e => setLenderName(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="AcNumber" className='custom-label'>From Account Number : </Label><br />
                    <Input
                      type="text"
                      name="AcNumber"
                      id="AcNumber"
                      className='custom-input-box'
                      value={fromAccountNumber}
                      onChange={e => setFromAccountNumber(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="AcNumber" className='custom-label'>To Account Number : </Label><br />
                    <Input
                      type="text"
                      name="AcNumber"
                      id="AcNumber"
                      className='custom-input-box'
                      value={toAccountNumber}
                      onChange={e => setToAccountNumber(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="amount" className='custom-label'>Amount : </Label><br />
                    <Input
                      type="text"
                      name="amount"
                      id="amount"
                      className='custom-input-box'
                      value={amount}
                      onChange={e => setAmount(e.target.value)} />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="amount" className='custom-label'>Amount : </Label><br />
                    <Input
                      type="text"
                      name="amount"
                      id="amount"
                      className='custom-input-box'
                      value={amount}
                      onChange={e => setAmount(e.target.value)} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="txnDate" className='custom-label'>Transaction Date : </Label><br />
                    <Input
                      type="date"
                      name="txnDate"
                      id="txnDate"
                      className='custom-input-box'
                      value={txnDate}
                      onChange={e => setTxnDate(e.target.value)} />
                  </FormGroup>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <Button className="generate-report-btn px-5" type="submit" onClick={handleAccountEntry}>
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </TabPane>
        <TabPane tabId="3">
          <div className='mainContainer'>
            <Form control={control} onSubmit={accountCreationHandleSubmit} className="pt-3 pb-5">
              <Row>
                <Col md="4">
                  <ValidatedField
                    label="Unique ID"
                    id="unique_id"
                    type="text"
                    name="unique_id"
                    placeholder="Unique ID"
                    register={register}
                    validate={{
                      required: { value: true, message: 'This field is required.' },
                    }}
                  />
                  <ValidatedField
                    label="Currency"
                    name="currency"
                    id="currency"
                    type="select"
                    placeholder="Currency"
                    register={register}
                    validate={{
                      required: { value: true, message: 'This field is required.' },
                    }}
                  >
                    <option value="Debit">Euros</option>
                    <option value="Credit">Pound</option>
                  </ValidatedField>
                  <ValidatedField
                    label="Real Bank Iban"
                    id="real_bank_iban"
                    type="text"
                    name="real_bank_iban"
                    placeholder="Real Bank Iban"
                    register={register}
                  />
                </Col>
                <Col md="4">
                  <ValidatedField
                    label="Account Name"
                    id="account_name"
                    type="text"
                    name="account_name"
                    placeholder="Account Name"
                    register={register}
                    validate={{
                      required: { value: true, message: 'This field is required.' },
                    }}
                  />
                  <ValidatedField
                    label="Account Balance"
                    id="account_balance"
                    type="number"
                    name="account_balance"
                    register={register}
                    placeholder="Account Balance"
                    validate={{
                      required: { value: true, message: 'This field is required.' },
                    }}
                  />
                </Col>
                <Col md="4">
                  <ValidatedField
                    label="Account type"
                    name="account_type"
                    id="account_type"
                    type="select"
                    placeholder="Account type"
                    register={register}
                    validate={{
                      required: { value: true, message: 'This field is required.' },
                    }}
                  >
                    <option value="Debit">Savings</option>
                    <option value="Credit">Current</option>
                  </ValidatedField>
                  <ValidatedField
                    label="Entity Type"
                    name="entity_type"
                    id="entity_type"
                    type="select"
                    placeholder="Entity Type"
                    register={register}
                    validate={{
                      required: { value: true, message: 'This field is required.' },
                    }}
                  >
                    <option value="Debit">Flender</option>
                    <option value="Credit">Funder</option>
                  </ValidatedField>
                </Col>
              </Row>
              <div className="d-flex flex-row-reverse">
                <Button type="submit" className='generate-report-btn px-5'>
                  Create
                </Button>
              </div>
            </Form>
            <div>

            </div>
          </div>
        </TabPane>
      </TabContent>
    </div >
  );
};

export default Reporting;

import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from './loan-profile.reducer';
import { getLoanIdList, getBorrowerIdList } from '../loan-repayment/loan-repayment.reducer';
import { getEntities as getBorrowerUniqueID } from '../borrower/borrower.reducer';
import NumberFormat from 'react-number-format';
import { TextFormat } from 'react-jhipster';
import { APP_DATE_FORMAT } from 'app/config/constants';

export const LoanProfile = () => {
  const dispatch = useAppDispatch();


  const [loanList, setLoanList] = useState<any>(null);
  const [borrowerName, setBorrowerName] = useState<string>('');
  const [borrowerId, setBorrowerId] = useState<string>('');
  const [loanId, setLoanId] = useState<string>('');

  const borrowerNameList = useAppSelector(state => state.borrower.entities);
  const borrowerIdList = useAppSelector(state => state.loanRepayment.borrowerIdList);
  const loanIdList = useAppSelector(state => state.loanRepayment.loanList);

  const handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch(getEntity(loanId)).then((res: any) => {
      if (res.payload && res.payload.data) {
        setLoanList(res.payload.data);
      }
    });
  };
  const handleBorrowerName = async event => {
    const selectedBorrowerName = event.target.value;
    setBorrowerName(selectedBorrowerName);

    try {
      await dispatch(getBorrowerIdList(selectedBorrowerName));
    } catch (error) {
      console.error('Error fetching borrower ID list:', error);
    }
  };

  const handleBorrowerId = async event => {
    const selectedBorrowerId = event.target.value;
    setBorrowerId(selectedBorrowerId);

    try {
      await dispatch(getLoanIdList({ borrowerId: selectedBorrowerId }));
    } catch (error) {
      console.error('Error fetching loans:', error);
    }
  };

  const handleLoanId = event => {
    setLoanId(event.target.value);
  };

  useEffect(() => {
    dispatch(getBorrowerUniqueID({}));
  }, [dispatch]);

  const currentDate = new Date().toLocaleDateString();

  return (
    <div className="pt-3">
      <h2 id="general-ledger-heading" data-cy="GeneralLedgerHeading">
        Loan Profile
      </h2>
      <div className="mb-3">
        <div className="row align-items-end dropDownContainer">
          <div className="col content-btn-div">
            <div className='max-div-search px-1'>
              <label className="custom-label">
                Select Borrower Name :
              </label>
              <select value={borrowerName} onChange={handleBorrowerName} className="custom-input-box">
                <option>Select Borrower</option>
                {borrowerNameList?.map((borrower, i) => (
                  <option key={i} value={borrower.borrowerBusinessName}>
                    {borrower.borrowerBusinessName}
                  </option>
                ))}
              </select>
            </div>
            <div className='max-div-search px-1'>
              <label className="custom-label">
                Select Borrower ID :
              </label>
              <select value={borrowerId} onChange={handleBorrowerId} className="custom-input-box" >
                <option>Select Borrower ID</option>
                {borrowerIdList.map((borrower_Id, index) => (
                  <option key={index} value={borrower_Id}>
                    {borrower_Id}
                  </option>
                ))}
              </select>
            </div>
            <div className='max-div-search px-1'>
              <label className="custom-label">
                Select Loan ID :
              </label>
              <select value={loanId} onChange={handleLoanId} className="custom-input-box">
                <option value="">Select Loan</option>
                {loanIdList.map((loan_Id, i) => (
                  <option key={i} value={loan_Id}>
                    {loan_Id}
                  </option>
                ))}
              </select>
            </div>
            <div className='max-div-search px-1 pt-1'>
              <Button type="button" className='search-content-btn ' onClick={handleSubmit}  >
                View
              </Button>
            </div>
          </div>
        </div>
        {loanList && typeof loanList === 'object' && (
          <div className="row col-md-12 d-flex mainContainer">
            <Row>
              <Col className='pt-3 pb-3'>
                <div className='sub-heading-custom pb-2'>Profile statement</div>
                <div className="card  card-custom">
                  <div className="card-body">
                    <div className='row pb-3'>
                      <div className="col-md-4 pt-3">
                        <div className='contend-heading'> Loan ID :</div>
                        <div className='contend-value'>{loanList?.loan_id}</div>
                      </div>
                      <div className="col-md-4 pt-3">
                        <div className='contend-heading'>Borrower ID : </div>
                        <div className='contend-value'>{loanList?.borrower_id}</div>
                      </div>

                      <div className="col-md-4 pt-3">
                        <div className='contend-heading'>Borrower Name :</div>
                        <div className='contend-value'>{loanList?.borrower_name}</div>
                      </div>

                      <div className="col-md-4 pt-3">
                        <div className='contend-heading'>Loan Activation Date :</div>
                        <div className='contend-value'>
                          {loanList?.profile_statement_from ? (
                            <TextFormat type="date" value={loanList?.profile_statement_from} format={APP_DATE_FORMAT} />
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 pt-3">
                        <div className='contend-heading'>Report Generation Date:</div>
                        <div className='contend-value'>{currentDate}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className='pt-3 pb-3'>
                <div className='sub-heading-custom pb-2'>Repayment Balance Indicator</div>
                <Card className='card-custom'>
                  <CardBody>
                    <Row>
                      <Col className='pt-3'>
                        <div className='contend-heading'>Principal Outstanding</div>
                        <div className='contend-value'>
                          <NumberFormat
                            value={loanList?.Outstanding_principle}
                            thousandSeparator={true}
                            prefix={' € '}
                            displayType={'text'}
                          />
                        </div>
                      </Col>
                      <Col className='pt-3'>
                        <div className='contend-heading'>Fee Outstanding</div>
                        <div className='contend-value'>
                          Flurish:
                          <NumberFormat
                            value={loanList?.flender_outstanding_fee}
                            thousandSeparator={true}
                            prefix={' € '}
                            displayType={'text'}
                          />
                        </div>
                        <div className='contend-value'>
                          Funder:
                          <NumberFormat
                            value={loanList?.funder_outstanding_fee}
                            thousandSeparator={true}
                            prefix={' € '}
                            displayType={'text'}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="table-responsive pt-5">
              <Table responsive className="table-striped table-bordered table-profile ">
                <tbody>
                  <tr>
                    <th scope="row"> Gross Loan</th>
                    <td>
                      <NumberFormat
                        value={loanList?.gross_loan !== null ? loanList?.gross_loan : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Origination Fee</th>
                    <td>
                      <NumberFormat
                        value={loanList?.origination_fee !== null ? loanList?.origination_fee : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Net Loan Amount</th>
                    <td>
                      <NumberFormat
                        value={loanList?.net_loan !== null ? loanList?.net_loan : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Underwritten Term </th>
                    <td>
                      <NumberFormat
                        value={loanList?.underwritten_term !== null ? loanList?.underwritten_term : 0}
                        thousandSeparator={true}
                        suffix={' Days'}
                        displayType={'text'}
                      />
                    </td>
                  </tr>
                  {/* <tr>
                    <th scope="row">Total Repayment</th>
                    <td>
                      <NumberFormat
                        value={loanList?.total_repayment !== null ? loanList?.total_repayment : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                  </tr> */}
                  <tr>
                    <th scope="row">Expected Daily Holdback Amount </th>
                    <td>
                      <NumberFormat
                        value={loanList?.daily_average !== null ? loanList?.daily_average : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Expected Daily POS Receipts </th>
                    <td>
                      <NumberFormat
                        value={loanList?.daily_average_pos_reciepts !== null ? loanList?.daily_average_pos_reciepts : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      /></td>
                  </tr>
                  <tr>
                    <th scope="row">HoldBack Rate(%)</th>
                    <td>
                      <NumberFormat
                        value={loanList?.holdback_rate !== null ? loanList?.holdback_rate : 0}
                        thousandSeparator={true}
                        suffix={' %'}
                        displayType={'text'}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Principal Paid </th>
                    <td>
                      <NumberFormat
                        value={loanList?.principal_paid !== null ? loanList?.principal_paid : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      /></td>
                  </tr>
                  <tr>
                    <th scope="row">Funder Total Fee Paid </th>
                    <td>
                      <NumberFormat
                        value={loanList?.funder_total_fee_paid !== null ? loanList?.funder_total_fee_paid : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Flurish Total Fee Paid  </th>
                    <td>
                      <NumberFormat
                        value={loanList?.flender_total_fee_paid !== null ? loanList?.flender_total_fee_paid : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Total Paid </th>
                    <td>
                      <NumberFormat
                        value={loanList?.total_repayment_done !== null ? loanList?.total_repayment_done : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Total Due </th>
                    <td>
                      <NumberFormat
                        value={loanList?.pending_repayment !== null ? loanList?.pending_repayment : 0}
                        thousandSeparator={true}
                        prefix={'€ '}
                        displayType={'text'}
                      /></td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        )}
      </div>
    </div >
  );
};

export default LoanProfile;

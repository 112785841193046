// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-body {
  margin: 0;
  background-image: url(../../../content/images/flender_image.jpg);
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
  background-color: antiquewhite;
  overflow: hidden;
}

.custom-card-background {
  background-color: rgb(255, 255, 255);
}

.left-aligned-label {
  display: block;
  text-align: left;
  color: #777;
  font-weight: 400;
  font-size: 1rem;
}

.logo-img {
  width: 25%;
}

.heading-color {
  color: #253746 !important;
}

.form-control {
  color: #495057;
  font-size: 14px;
}

.form-control::-moz-placeholder {
  font-size: 12px;
}

.form-control::placeholder {
  font-size: 12px;
}

.password-toggle-icon {
  position: absolute;
  bottom: 10px;
  right: 20px;
  cursor: pointer;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/login/login.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,gEAAA;EACA,sBAAA;EACA,aAAA;EACA,4BAAA;EACA,8BAAA;EACA,gBAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAEA;EACE,cAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AACA;EACE,cAAA;EACA,eAAA;AAEF;;AACA;EACE,eAAA;AAEF;;AAHA;EACE,eAAA;AAEF;;AACA;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;AAEF","sourcesContent":[".custom-body {\n  margin: 0;\n  background-image: url(../../../content/images/flender_image.jpg);\n  background-size: cover;\n  height: 100vh;\n  background-repeat: no-repeat;\n  background-color: antiquewhite;\n  overflow: hidden;\n}\n\n.custom-card-background {\n  background-color: rgba(255, 255, 255);\n}\n\n.left-aligned-label {\n  display: block;\n  text-align: left;\n  color: #777;\n  font-weight: 400;\n  font-size: 1rem;\n}\n\n.logo-img {\n  width: 25%;\n}\n\n.heading-color {\n  color: #253746 !important;\n}\n.form-control {\n  color: #495057;\n  font-size: 14px;\n}\n\n.form-control::placeholder {\n  font-size: 12px;\n}\n\n.password-toggle-icon {\n  position: absolute;\n  bottom: 10px;\n  right: 20px;\n  cursor: pointer;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

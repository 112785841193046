import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getTermloanListEntity, termLoanIDList } from '../term-loan-profile/term-loan-profile.reducer';
import { getBorrowerIdList } from '../loan-repayment/loan-repayment.reducer';
import { termLoanBorrowerList } from '../term-loan-profile/term-loan-profile.reducer';
import {
  getTermloanScheduleEntity,
  addBreakMonths,
  editDueDate,
  editInstallmentAmount,
  updateFee,
  editTotalPaid,
  getTermLoanScheduleExcelReport,
  getReverseRepaymentList,
  applyReverseRepayment,
  updateServicingFee,
} from './term-loan-schedule.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Select from 'react-select';
import { faBook, faDownload, faFileExcel, faFilePdf, faPen, faSpinner, faXmark } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

export const TermLoanSchedule = () => {
  const dispatch = useAppDispatch();
  const [borrowerName, setBorrowerName] = useState<string>('');
  const [borrowerId, setBorrowerId] = useState<string>('');
  const [loanId, setLoanId] = useState<string>('');
  const [showAddTermBreak, setShowAddTermBreak] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [viewClicked, setViewClicked] = useState(false);
  const [breakInstalment, setBreakInstalment] = useState<number>(0);
  const [breakPeriod, setBreakPeriod] = useState<number>(0);
  const [editingDueDate, setEditingDueDate] = useState<string | null>(null);
  const [newDueDate, setNewDueDate] = useState<string>('');
  const [isDueDateEditEnabled, setIsDueDateEditEnabled] = useState(false);
  const [editingFee, setEditingFee] = useState<string | null>(null);
  const [newFeeAmount, setNewFeeAmount] = useState<string | null>(null);
  const [newServicingFeeAmount, setNewServicingFeeAmount] = useState<string | null>(null);
  const [newInstallmentAmount, setNewInstallmentAmount] = useState<string>('');
  const [editInstallment, setEditInstallment] = useState(false);
  const [editFeeDetails, seteditFeeDetails] = useState(false);
  const [editServicingFeeDetails, setEditServicingFeeDetails] = useState(false);
  const [newTotalExpected, setNewTotalExpected] = useState<string>('');
  const [editingTotalExpected, setEditingTotalExpected] = useState<number | null>(null);
  const [searchableLoanId, setSearchableLoanId] = useState(null);
  const [newTotalPaid, setNewTotalPaid] = useState<string>('');
  const [editTotalPaymentDetails, setEditTotalPaymentDetails] = useState<boolean>(false);
  const borrowerNameList = useAppSelector(state => state.termLoanProfile?.termLoanBorrowers);
  const borrowerIdList = useAppSelector(state => state.loanRepayment?.borrowerIdList);
  const termLoanIdList = useAppSelector(state => state.termLoanProfile?.loanIdList);
  const termLoanSchedule = useAppSelector(state => state.termLoanSchedule?.entity);
  const reverseRepaymentList = useAppSelector((state: any) => state.termLoanSchedule?.reverseRepayentity);
  const termLoanFullIdList = useAppSelector(state => state.termLoanProfile?.termLoanIdListEntity);
  const [selectedLoan, setSelectedLoan] = useState({ loanId: '', instalment: 0, totalPaid: '', totalDue: '' });
  const [showRepaymentEditModal, setshowRepaymentEditModal] = useState(false);
  const [showReverseRepaymentModal, setshowReverseRepaymentModal] = useState(false);
  const [postingDate, setNewPostingDate] = useState<string>(new Date().toISOString());
  const [postingNote, setNewpostingNote] = useState<string>('');
  const [reverseRepaymentDisplay, setReverseRepaymentDisplay] = useState<boolean>(false);
  const [showFirstTable, setShowFirstTable] = useState(false);
  const [showSecondTable, setShowSecondTable] = useState(false);
  const [isDueDateModalOpen, setisDueDateModalOpen] = useState(false);
  const [isAdhocConfirmationModalOpen, setIsAdhocConfirmationModalOpen] = useState(false);
  const [adhocModalPayload, setAdhocModalPayload] = useState(null);
  const [isLegalCollectionConfirmationModalOpen, setIsLegalCollectionConfirmationModalOpen] = useState(false);
  const [legalCollectionModalPayload, setLegalCollectionModalPayload] = useState(null);
  const [isExitFeeConfirmationModalOpen, setIsExitFeeConfirmationModalOpen] = useState(false);
  const [exitFeeModalPayload, setExitFeeModalPayload] = useState(null);
  const [editingServicingFee, setEditingServicingFee] = useState<number | null>(null);
  const [newServicingFee, setNewServicingFee] = useState<string>('');
  const [servicingFeeModalPayload, setServicingFeeModalPayload] = useState<{
    loanId: string;
    instalment: number;
    servicingFee: string;
  } | null>(null);
  const [isServicingFeeModalOpen, setIsServicingFeeModalOpen] = useState(false);

  const [dueDateModalPayload, setdueDateModalPayload] = useState({
    loanId: '',
    instalment: '',
    dueDate: '',
  });
  const [isTotalExpectedModalOpen, setisTotalExpectedModalOpen] = useState(false);
  const [totalExpectedModalPayload, setTotalExpectedModalPayload] = useState({
    loanId: '',
    instalment: 0,
    totalExpected: '',
  });

  useEffect(() => {
    dispatch(termLoanBorrowerList({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(termLoanIDList({}));
  }, [dispatch]);

  const handleBorrowerName = async event => {
    const selectedBorrowerName = event.target.value;
    setBorrowerName(selectedBorrowerName);
    setBorrowerId('');
    setLoanId('');
    setErrorMessage(null);
    try {
      await dispatch(getBorrowerIdList(selectedBorrowerName));
    } catch (error) {
      setErrorMessage('Error fetching borrower ID list');
    }
  };

  const handleBorrowerId = async event => {
    const selectedBorrowerId = event.target.value;
    setBorrowerId(selectedBorrowerId);
    setLoanId('');
    setErrorMessage(null);

    try {
      await dispatch(getTermloanListEntity(selectedBorrowerId));
    } catch (error) {
      setErrorMessage('Error fetching loan ID list');
    }
  };

  const handleLoanIdChange = event => {
    const selectedLoanId = event.target.value;
    setLoanId(selectedLoanId);
    setErrorMessage(null);
  };

  const viewLoanSchedule = async () => {
    if (loanId) {
      setLoading(true);
      setErrorMessage('');
      try {
        await dispatch(getTermloanScheduleEntity(loanId));
      } catch (error) {
        setErrorMessage('Error fetching loan schedule');
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMessage('No Loan ID selected');
    }
  };

  const handleViewLoanScheduleClick = () => {
    viewLoanSchedule();
    setViewClicked(true);
    setShowFirstTable(true);
    setShowSecondTable(false);
    setShowAddTermBreak(false);
    setIsDueDateEditEnabled(false);
    setEditInstallment(false);
    seteditFeeDetails(false);
    setEditTotalPaymentDetails(false);
    setReverseRepaymentDisplay(false);
    setEditServicingFeeDetails(false);
    setEditingServicingFee(null);
    setEditingDueDate(null);
    setEditingTotalExpected(null);
    setEditingFee(null);
  };

  const handleAddTermBreakToggle = () => {
    setShowAddTermBreak(!showAddTermBreak);
    setReverseRepaymentDisplay(false);
  };

  const handleAddTermBreak = async () => {
    if (loanId == null || breakInstalment == null || breakPeriod == null) {
      setErrorMessage('Please fill in all the required fields.');
      return;
    }
    try {
      await dispatch(addBreakMonths({ loanId, instalment_number: breakInstalment, breakPeriod }));
      viewLoanSchedule();
    } catch (error) {
      setErrorMessage('Error adding break months');
    }
  };

  const handleDueDateChange = (event, instalment) => {
    const selectedDate = event.target.value;
    if (selectedDate) {
      setNewDueDate(selectedDate);
      setEditingDueDate(instalment);
    } else {
      setErrorMessage('Please enter a valid date');
    }
  };

  const handleSaveDueDate = async (loanId, instalmentNumber, currentDueDate) => {
    if (!newDueDate) {
      setErrorMessage('Please enter a valid due date.');
      return;
    }

    const selectedDate = new Date(newDueDate);
    const currentDate = new Date(currentDueDate);

    // if (selectedDate <= currentDate) {
    //   setErrorMessage('New due date cannot be older than the current due date.');
    //   alert('New due date cannot be older than the current due date.');

    //   return;
    // }

    const formattedNewDueDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 12, 0, 0));

    const apiFormattedNewDueDate = formattedNewDueDate.toISOString().slice(0, 19);

    try {
      await dispatch(editDueDate({ loanId, instalmentNumber, newDueDate: apiFormattedNewDueDate }));
      viewLoanSchedule();
      setEditingDueDate(null);
      setErrorMessage(null);
    } catch (error) {
      console.error(error);
      setErrorMessage('Error updating due date');
    }
    setIsDueDateEditEnabled(false);
  };

  const handleEditDueDateToggle = () => {
    if (isDueDateEditEnabled) {
      setEditingDueDate(null);
      setNewDueDate('');
    }
    setIsDueDateEditEnabled(!isDueDateEditEnabled);
    setReverseRepaymentDisplay(false);
  };

  const handleCancelEdit = () => {
    setEditingDueDate(null);
    setNewDueDate('');
    setReverseRepaymentDisplay(false);
  };

  const handleEditInstallmentAmount = () => {
    if (!editInstallment) {
      setEditInstallment(true);
    }
    setReverseRepaymentDisplay(false);
  };

  const handleCancelEditInstallment = () => {
    setEditInstallment(false);
    setNewInstallmentAmount('');
    setEditingTotalExpected(null);
    setReverseRepaymentDisplay(false);
  };
  const handleEditFeeDetails = () => {
    if (!editFeeDetails) {
      seteditFeeDetails(true);
    }
    setReverseRepaymentDisplay(false);
  };

  const handleCancelEditFeeDetails = () => {
    seteditFeeDetails(false);
    setEditingFee(null);
    setNewFeeAmount(null);
    setReverseRepaymentDisplay(false);
  };

  const handleEditServicingFeeClick = () => {
    setEditServicingFeeDetails(true);
  };
  const handleCancelEditServicingFee = () => {
    setEditServicingFeeDetails(false);
    setEditingServicingFee(null);
    setNewServicingFee(null);
  };
  const handleSaveTotalExpected = (loanId: string, instalmentNumber: number, newTotalExpected: string) => {
    if (!newTotalExpected) {
      alert('Please enter a valid installment amount.');
      return;
    }

    const payload = {
      data: {
        loan_id: loanId,
        instalment_number: instalmentNumber,
        new_instalment_amount: parseFloat(newTotalExpected),
      },
    };

    dispatch(editInstallmentAmount(payload))
      .then((response: any) => {
        if (response.meta.requestStatus === 'fulfilled') {
          alert('Installment amount updated successfully!');
          setEditInstallment(false);
          setNewInstallmentAmount('');
          setEditingTotalExpected(null);
          viewLoanSchedule();
        }
        setErrorMessage(response.message);
      })
      .catch(response => {
        setErrorMessage(response.message);
      });
  };

  const handleSaveFee = (loanId: string, installment: number, feeAmount: string | null, feeType: string) => {
    if (!feeAmount) {
      alert('Fee amount is required!');
      return;
    }
    const payload = {
      loanId,
      installment,
      newFeeAmount: parseFloat(feeAmount),
      feeType,
    };

    dispatch(updateFee(payload))
      .then(response => {
        if (response.meta.requestStatus === 'fulfilled') {
          alert('Fee updated successfully!');
          setEditingFee(null);
          setNewFeeAmount(null);
          fetchLoanDetails();
          viewLoanSchedule();
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const handleSaveServicingFee = (loanId: string, installment: number, fee_amount: string | null, feeType: string) => {
    if (!fee_amount) {
      alert('Servicing Fee amount is required!');
      return;
    }

    const payload = {
      loanId,
      installment,
      fee_amount: parseFloat(fee_amount),
      feeType: 'Servicing Fee',
    };

    dispatch(updateServicingFee(payload))
      .then(response => {
        if (response.meta.requestStatus === 'fulfilled') {
          alert('Fee updated successfully!');
          setEditingServicingFee(null);
          setNewServicingFeeAmount(null);
          setEditServicingFeeDetails(null);
          fetchLoanDetails();
          viewLoanSchedule();
        }
      })
      .catch(error => {
        setEditingServicingFee(null);
        console.error('Error:', error);
      });
  };

  const fetchLoanDetails = () => {
    console.log('Fetching loan details...');
  };

  const handleLoanIdSearch = selectedOption => {
    setLoanId(selectedOption?.value || '');
    setSearchableLoanId(selectedOption);
  };

  const customFilterOption = (option, inputValue) => {
    if (!inputValue) return true;
    return option.label.toLowerCase().startsWith(inputValue.toLowerCase());
  };
  const customComponents = {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
  };

  const loanIdOptions = termLoanFullIdList?.map(loanId => ({
    value: loanId,
    label: loanId,
  }));

  // const handleDownloadTermLoanExcel = async () => {
  //   try {
  //     await dispatch(
  //       getTermLoanScheduleExcelReport({
  //         loanId: loanId ? loanId : '',
  //       }),
  //     );
  //   } catch (error) {
  //     console.error('Unexpected error fetching data:', error);
  //   }
  // };

  //--------------------------------------------------
  const handleDownloadTermLoanExcel = async () => {
    setLoadingReport(true);
    try {
      if (loanId) {
        await dispatch(
          getTermLoanScheduleExcelReport({
            loanId: loanId ? loanId : '',
          }),
        );
      } else {
        const response = await axios.get(`${REPORT_URL}/jasperserver/rest_v2/reports/jasper/Loan_Schedule_Excel.csv`, {
          auth: {
            username: 'jasperadmin',
            password: 'bitnami',
          },
          responseType: 'arraybuffer',
        });

        const file = new Blob([response.data], { type: 'text/csv' });
        const fileURL = URL.createObjectURL(file);

        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `Loan_Schedule_Excel.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Unexpected error fetching data:', error);
    } finally {
      setLoadingReport(false);
    }
  };

  const handleSaveTotalPaid = (loanId: string, instalment: number, totalPaid: string | null, postingDate: string, postingNote: string) => {
    if (!totalPaid) {
      alert('Total Paid amount is required!');
      return;
    }
    setshowRepaymentEditModal(false);
    if (postingDate) {
      dispatch(editTotalPaid({ loanId, instalmentNumber: instalment, amount: parseFloat(totalPaid), postingDate, postingNote }))
        .then(response => {
          if (response.meta.requestStatus === 'fulfilled') {
            alert('Total Paid updated successfully!');
            setNewTotalPaid(null);
            dispatch(getTermloanScheduleEntity(loanId));
            setshowRepaymentEditModal(false);
          }
        })
        .catch(error => {
          setshowRepaymentEditModal(false);
          console.error('Error:', error);
        });
    } else alert('Enter Posting Date');
  };

  const handleEditTotalPaymentDetails = () => {
    setEditTotalPaymentDetails(true);
    setReverseRepaymentDisplay(false);
  };
  const handleCancelEditTotalPaymentDetails = () => {
    setEditTotalPaymentDetails(false);
    setReverseRepaymentDisplay(false);
  };

  const handleEditTotalFeeClick = (loanId, instalment, totalPaid, totalDue) => {
    setshowRepaymentEditModal(true);
    setSelectedLoan({ loanId, instalment, totalDue, totalPaid: totalPaid?.toString() || '' });
    setNewTotalPaid(totalPaid?.toString() || '');
  };

  const handleReverseRepaymentApply = () => {
    if (reverseRepaymentList[0]?.action !== 'REPAYMENT') {
      alert('The last repayment has already been reversed');
    } else {
      setshowReverseRepaymentModal(true);
    }
  };

  const handleInputChange = inputValue => {
    setSearchableLoanId(inputValue);
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleReverseRepayment = async () => {
    setShowFirstTable(false);
    setShowSecondTable(true);
    setReverseRepaymentDisplay(true);

    if (loanId) {
      try {
        await dispatch(getReverseRepaymentList(loanId));
      } catch (error) {
        setErrorMessage('Failed to reverse repayment: ' + error.message);
      }
    } else {
      setErrorMessage('Please select a Loan ID before reversing repayment.');
    }
  };
  const handleCancelReverseRepayment = () => {
    setReverseRepaymentDisplay(false);
  };

  useEffect(() => {
    setShowFirstTable(false);
    setShowSecondTable(false);
  }, []);

  const handleRepaymentReversalAction = ({
    loanId,
    instalment,
    repaidAmount,
  }: {
    loanId: string;
    instalment: number;
    repaidAmount: number;
  }) => {
    dispatch(
      applyReverseRepayment({
        loanId,
        instalmentNumber: instalment,
        amount: repaidAmount,
      }),
    )
      .then(response => {
        if (response.meta.requestStatus === 'fulfilled') {
          dispatch(getTermloanScheduleEntity(loanId));
          setshowReverseRepaymentModal(false);
          setReverseRepaymentDisplay(false);
        } else {
          console.error('Reversal action failed:', response);
        }
      })
      .catch(error => {
        console.error('Error during reversal action:', error);
      })
      .finally(() => {
        setshowReverseRepaymentModal(false);
      });
  };

  const dueDateRef = useRef(null);
  const totalExpectedRef = useRef(null);
  const totalPaidRef = useRef(null);
  const feeDetailsRef = useRef(null);
  const servicingFeeRef = useRef(null);

  useEffect(() => {
    const scrollToHighlight = ref => {
      if (ref && ref.current) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
          block: 'nearest',
        });
      }
    };

    if (isDueDateEditEnabled) scrollToHighlight(dueDateRef);
    else if (editInstallment) scrollToHighlight(totalExpectedRef);
    else if (editTotalPaymentDetails) scrollToHighlight(totalPaidRef);
    else if (editFeeDetails) scrollToHighlight(feeDetailsRef);
    else if (editServicingFeeDetails) scrollToHighlight(servicingFeeRef);
  }, [isDueDateEditEnabled, editInstallment, editTotalPaymentDetails, editFeeDetails, editServicingFeeDetails]);

  return (
    <div className="pt-3">
      <div className="d-flex justify-content-between align-items-end">
        <h2 id="general-ledger-heading" data-cy="GeneralLedgerHeading">
          Term Loan Schedule
        </h2>
        <div>
          <Button className=" generate-report-btn " onClick={handleDownloadTermLoanExcel} disabled={loading}>
            {loadingReport ? (
              <>
                <FontAwesomeIcon icon={faSpinner} spin={loading} />
                &nbsp;Preparing Report
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faDownload} />
                &nbsp;Generate Report
              </>
            )}
          </Button>
        </div>
      </div>

      <div className="row justify-content-between align-items-end dropDownContainer pb-3">
        <div className="col-auto content-btn-div">
          <div className="max-div-search px-1">
            <label className="custom-label">Borrower Name:</label>
            <select value={borrowerName} onChange={handleBorrowerName} className="custom-input-box">
              <option>Select Borrower</option>
              {borrowerNameList?.map((borrower, i) => (
                <option key={i} value={borrower.borrowerBusinessName}>
                  {borrower.borrowerBusinessName}
                </option>
              ))}
            </select>
          </div>
          <div className="max-div-search px-1">
            <label className="custom-label">Borrower ID:</label>
            <select value={borrowerId} onChange={handleBorrowerId} disabled={!borrowerName} className="custom-input-box">
              <option value="">Select Borrower ID</option>
              {borrowerIdList?.map((borrower_Id, index) => (
                <option key={index} value={borrower_Id}>
                  {borrower_Id}
                </option>
              ))}
            </select>
          </div>
          {borrowerId && (
            <>
              <div className="max-div-search px-1">
                <label className="custom-label">Loan ID:</label>
                <select value={loanId} onChange={handleLoanIdChange} className="custom-input-box" disabled={!borrowerId}>
                  <option value="">Select Loan</option>
                  {termLoanIdList?.map((loanId, i) => (
                    <option key={i} value={loanId}>
                      {loanId}
                    </option>
                  ))}
                </select>
              </div>

              <div className="px-1 pt-1">
                <Button className="search-content-btn" onClick={handleViewLoanScheduleClick}>
                  View
                </Button>
              </div>
            </>
          )}
          {!borrowerId && (
            <>
              <div className="max-div-search px-1">
                <label className="custom-label">Loan ID:</label>
                <Select
                  options={loanIdOptions}
                  value={searchableLoanId}
                  onChange={handleLoanIdSearch}
                  onInputChange={handleInputChange}
                  components={customComponents}
                  placeholder="Select or search Loan ID..."
                  className="react-select-container"
                  classNamePrefix="react-select"
                  filterOption={customFilterOption}
                />
              </div>
              <div className="px-1 pt-1">
                <Button className="search-content-btn" onClick={() => handleViewLoanScheduleClick()}>
                  Search
                </Button>
              </div>
            </>
          )}
        </div>
        {loanId.length > 0 && (
          <div className="col text-end">
            {!showAddTermBreak &&
              !isDueDateEditEnabled &&
              !editInstallment &&
              !editFeeDetails &&
              !editTotalPaymentDetails &&
              !reverseRepaymentDisplay &&
              !editServicingFeeDetails && (
                <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
                  <DropdownToggle caret className="custom-dropdown-btn">
                    Choose an action
                  </DropdownToggle>
                  <DropdownMenu className="custom-dropdown-menu">
                    <DropdownItem onClick={handleAddTermBreakToggle}>Add Payment Break</DropdownItem>
                    <DropdownItem onClick={handleEditDueDateToggle}>Edit Due Date</DropdownItem>
                    <DropdownItem onClick={handleEditInstallmentAmount}>Edit Installment Amount</DropdownItem>
                    <DropdownItem onClick={handleEditFeeDetails}>Edit Fee Details</DropdownItem>
                    <DropdownItem onClick={handleEditTotalPaymentDetails}>Apply Repayment</DropdownItem>
                    <DropdownItem onClick={handleReverseRepayment}>Reverse Repayment</DropdownItem>
                    <DropdownItem onClick={handleEditServicingFeeClick}>Edit Servicing Fee </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}
            {showAddTermBreak && (
              <Button className="close-btn" onClick={handleAddTermBreakToggle}>
                Cancel
              </Button>
            )}
            {isDueDateEditEnabled && (
              <Button className="close-btn" onClick={handleEditDueDateToggle}>
                Cancel Editing
              </Button>
            )}
            {editInstallment && (
              <Button className="close-btn" onClick={handleCancelEditInstallment}>
                Cancel Editing
              </Button>
            )}
            {editFeeDetails && (
              <Button className="close-btn" onClick={handleCancelEditFeeDetails}>
                Cancel Editing
              </Button>
            )}
            {editTotalPaymentDetails && (
              <Button className="close-btn" onClick={handleCancelEditTotalPaymentDetails}>
                Cancel Editing
              </Button>
            )}
            {reverseRepaymentDisplay && (
              <Button className="close-btn" onClick={handleCancelReverseRepayment}>
                Cancel
              </Button>
            )}
            {editServicingFeeDetails && (
              <Button className="close-btn" onClick={handleCancelEditServicingFee}>
                Cancel Editing
              </Button>
            )}
          </div>
        )}
      </div>

      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      {/* {!showAddTermBreak && viewClicked && loanId.length > 0 && (
        <div className="d-flex">
          <>
            <Button className="customButton mb-4" onClick={handleAddTermBreakToggle}>
              Add Payment Break
            </Button>
            <Button className="customButton ms-3 mb-4" onClick={handleEditDueDateToggle}>
              {isDueDateEditEnabled ? 'Cancel Editing' : 'Edit Due Date'}
            </Button>

            <Button
              className="customButton ms-3 mb-4"
              onClick={editInstallment ? handleCancelEditInstallment : handleEditInstallmentAmount}
            >
              {editInstallment ? 'Cancel Editing' : 'Edit Installment Amount'}
            </Button>
            <Button className="customButton ms-3 mb-4" onClick={editFeeDetails ? handleCancelEditFeeDetails : handleEditFeeDetails}>
              {editFeeDetails ? 'Cancel Editing' : 'Edit Fee Details'}
            </Button>
            <Button
              className="customButton ms-3 mb-4"
              onClick={editTotalPaymentDetails ? handleCancelEditTotalPaymentDetails : handleEditTotalPaymentDetails}
            >
              {editTotalPaymentDetails ? 'Cancel Editing' : 'Apply Repayment'}
            </Button>
            {/* <Button className="customButton ms-3 mb-4" onClick={handleReverseRepayment}>
              Reverse Repayment
            </Button> 
            <Button
              className="customButton ms-3 mb-4"
              onClick={reverseRepaymentDisplay ? handleCancelReverseRepayment : handleReverseRepayment}
            >
              {reverseRepaymentDisplay ? 'Cancel Editing' : 'Reverse Repayment'}
            </Button>
          </>
        </div>
      )} */}

      {showAddTermBreak && termLoanSchedule && termLoanSchedule.length > 0 && (
        <>
          <div className="sub-heading-custom mt-3">Add Term Break</div>
          <Card className="mt-2 mb-3 card-custom">
            <CardBody>
              {/* <div className="d-flex justify-content-end align-items-center">
                <Button onClick={handleAddTermBreakToggle} style={{ cursor: 'pointer' }} className="close-btn">
                  X
                </Button>
              </div> */}
              <div className="row">
                <div className="col-md-4 pt-3">
                  <Label for="loanIdInput" className="custom-label">
                    Term Loan Id
                  </Label>
                  <Input id="loanIdInput" type="text" value={loanId} readOnly className="custom-input-box" />
                </div>
                <div className="col-md-4 pt-3">
                  <Label for="Installment" className="custom-label">
                    Installment :
                  </Label>
                  <select
                    id="Installment"
                    value={breakInstalment}
                    onChange={e => setBreakInstalment(Number(e.target.value))}
                    className="custom-input-box"
                  >
                    <option value="">Select Installment</option>
                    {termLoanSchedule?.map((termLoan, i) => (
                      <option key={i} value={termLoan.instalment}>
                        {termLoan.instalment}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 pt-3">
                  <Label for="breakMonths" className="custom-label">
                    Break Months :
                  </Label>
                  <select
                    id="breakMonths"
                    value={breakPeriod !== null && breakPeriod !== undefined ? breakPeriod : ''}
                    onChange={e => setBreakPeriod(Number(e.target.value))}
                    className="custom-input-box"
                  >
                    <option value="">Select Break Months</option>
                    {Array.from({ length: 13 }, (_, month) => (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="text-end pt-3">
                <Button
                  onClick={handleAddTermBreak}
                  className="generate-report-btn"
                  disabled={!loanId || !breakInstalment || breakPeriod === null || breakPeriod === undefined}
                >
                  Add Term Break
                </Button>
              </div>
            </CardBody>
          </Card>
        </>
      )}
      {!errorMessage && !reverseRepaymentDisplay && termLoanSchedule && termLoanSchedule.length > 0 && (
        <div className="mainContainer table-wrapper custom-table-responsive" style={{ overflowY: 'auto' }}>
          <Table responsive className="table-striped table-bordered">
            <thead>
              <tr>
                <th>Id</th>
                <th>Loan Id</th>
                <th>Installment</th>
                <th ref={dueDateRef} className={`${isDueDateEditEnabled ? 'edit-highlight' : ''}`}>
                  Due Date
                </th>
                <th>Principal Balance Opening</th>
                <th>Principal Expected</th>
                <th>Principal Balance Closing</th>
                <th>Interest Expected</th>

                <th ref={servicingFeeRef} className={`${editServicingFeeDetails ? 'edit-highlight' : ''}`}>
                  Fees Expected
                </th>
                <th>Penalty Expected</th>
                <th ref={totalExpectedRef} className={`${editInstallment ? 'edit-highlight' : ''}`}>
                  Total Expected
                </th>
                <th className="highlight-one">Principal Paid</th>
                <th className="highlight-one">Interest Paid</th>
                <th className="highlight-one">Fee Paid</th>
                <th className="highlight-one">Penalty Paid</th>
                <th ref={totalPaidRef} className={`${editTotalPaymentDetails ? 'edit-highlight' : 'highlight-one'}`}>
                  Total Paid
                </th>
                <th className="highlight-one">Paid Date</th>
                <th className="highlight-two">Principal Due</th>
                <th className="highlight-two">Interest Due</th>
                <th className="highlight-two">Fee Due</th>
                <th className="highlight-two">Penalty Due</th>
                <th className="highlight-two">Total Due</th>
                <th>State</th>
                <th>Type of Payment</th>
                <th ref={feeDetailsRef} className={`${editFeeDetails ? 'edit-highlight' : ''}`}>
                  Ad Hoc Fee
                </th>
                <th ref={feeDetailsRef} className={`${editFeeDetails ? 'edit-highlight' : ''}`}>
                  Legal Collection Fee
                </th>
                <th ref={feeDetailsRef} className={`${editFeeDetails ? 'edit-highlight' : ''}`}>
                  Exit Fee
                </th>
                <th>Expected Instalment Date</th>
                <th>Interest Days</th>
                <th>Billing Days Adjustment</th>
              </tr>
            </thead>
            <tbody>
              {termLoanSchedule.map((termLoan, index) => (
                <tr key={index}>
                  <td>{termLoan.id ? termLoan.id : 0}</td>
                  <td>{termLoan.loanId ? termLoan.loanId : 0}</td>
                  <td>{termLoan.instalment ? termLoan.instalment : 0}</td>
                  <td className={`${isDueDateEditEnabled && 'edit-highlight '}`}>
                    {editingDueDate === termLoan.instalment ? (
                      <>
                        <input
                          type="date"
                          value={moment(newDueDate).format('YYYY-MM-DD')}
                          onChange={e => handleDueDateChange(e, termLoan.instalment)}
                          className="custom-input-box"
                        />
                        <Button
                          className="inner-table-custom-button"
                          onClick={() => {
                            setdueDateModalPayload({
                              loanId: termLoan?.loanId,
                              instalment: termLoan?.instalment,
                              dueDate: newDueDate,
                            });
                            setisDueDateModalOpen(true);
                          }}
                        >
                          <FontAwesomeIcon icon="save" />
                        </Button>
                        <Button className="inner-table-custom-button" onClick={handleCancelEdit}>
                          <FontAwesomeIcon icon={faXmark} />
                        </Button>
                        <Modal isOpen={isDueDateModalOpen} onHide={() => setisDueDateModalOpen(false)} centered>
                          {/* <ModalHeader closeButton>
                            <ModalHeader></ModalHeader>
                          </ModalHeader> */}
                          <ModalBody>
                            <h2>Confirm Save</h2>
                            <div className="contend-heading">Are you sure you want to save the following details?</div>
                            <div className="content-value-text pt-3">
                              Loan ID: <span>{dueDateModalPayload?.loanId}</span>
                            </div>
                            <div className="content-value-text">
                              Instalment: <span>{dueDateModalPayload?.instalment}</span>
                            </div>
                            <div className="content-value-text">
                              Due Date: <span>{dueDateModalPayload?.dueDate}</span>
                            </div>
                            <div className="d-flex justify-content-end pt-3 ">
                              <div className="px-2">
                                <Button className="search-content-btn px-3" onClick={() => setisDueDateModalOpen(false)}>
                                  Cancel
                                </Button>
                              </div>
                              <div className="px-2">
                                <Button
                                  className="generate-report-btn px-4"
                                  onClick={() => {
                                    handleSaveDueDate(
                                      dueDateModalPayload?.loanId,
                                      dueDateModalPayload?.instalment,
                                      dueDateModalPayload?.dueDate,
                                    );
                                    setisDueDateModalOpen(false);
                                  }}
                                >
                                  Submit
                                </Button>
                              </div>
                            </div>
                          </ModalBody>
                        </Modal>
                      </>
                    ) : (
                      <>
                        {termLoan.dueDate ? moment(termLoan.dueDate).format('YYYY-MM-DD') : '00/00/0000'}
                        {isDueDateEditEnabled && termLoan.state !== 'PAID' && (
                          <Button
                            className="inner-table-custom-button"
                            onClick={() => {
                              setEditingDueDate(termLoan.instalment);
                              setNewDueDate(termLoan.dueDate);
                            }}
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </Button>
                        )}
                      </>
                    )}
                  </td>
                  <td>{termLoan.principalBalanceOpening ? termLoan.principalBalanceOpening : 0}</td>
                  <td>{termLoan.principalExpected ? termLoan.principalExpected : 0}</td>
                  <td>{termLoan.principalBalanceClosing ? termLoan.principalBalanceClosing : 0}</td>
                  <td>{termLoan.interestExpected ? termLoan.interestExpected : 0}</td>

                  <td className={`${editServicingFeeDetails && 'edit-highlight'}`}>
                    {editingServicingFee === termLoan.instalment ? (
                      <>
                        <input
                          type="number"
                          value={newServicingFee}
                          onChange={e => setNewServicingFee(e.target.value)}
                          placeholder="Servicing Fee"
                          className="custom-input-box"
                          min="0"
                        />
                        <Button
                          className="inner-table-custom-button"
                          onClick={() => {
                            setServicingFeeModalPayload({
                              loanId: termLoan?.loanId,
                              instalment: termLoan?.instalment,
                              servicingFee: newServicingFee,
                            });
                            setIsServicingFeeModalOpen(true);
                          }}
                          disabled={!newServicingFee}
                        >
                          <FontAwesomeIcon icon="save" />
                        </Button>
                        <Button className="inner-table-custom-button" onClick={() => setEditingServicingFee(null)}>
                          <FontAwesomeIcon icon={faXmark} />
                        </Button>

                        <Modal isOpen={isServicingFeeModalOpen} onHide={() => setIsServicingFeeModalOpen(false)} centered>
                          <ModalBody>
                            <h2>Confirm Save</h2>
                            <div className="contend-heading">Are you sure you want to save the following details?</div>
                            <div className="content-value-text pt-3">
                              <strong>Loan ID:</strong> {servicingFeeModalPayload?.loanId}
                            </div>
                            <div className="content-value-text">
                              <strong>Instalment:</strong> {servicingFeeModalPayload?.instalment}
                            </div>
                            <div className="content-value-text">
                              <strong>Servicing Fee:</strong> {servicingFeeModalPayload?.servicingFee}
                            </div>
                            <div className="d-flex justify-content-end pt-3">
                              <div className="px-2">
                                <Button className="search-content-btn px-3" onClick={() => setIsServicingFeeModalOpen(false)}>
                                  Cancel
                                </Button>
                              </div>
                              <div className="px-2">
                                <Button
                                  className="generate-report-btn px-4"
                                  onClick={() => {
                                    handleSaveServicingFee(
                                      servicingFeeModalPayload.loanId,
                                      servicingFeeModalPayload.instalment,
                                      servicingFeeModalPayload.servicingFee,
                                      servicingFeeModalPayload.servicingFee,
                                    );
                                    setIsServicingFeeModalOpen(false);
                                  }}
                                >
                                  Submit
                                </Button>
                              </div>
                            </div>
                          </ModalBody>
                        </Modal>
                      </>
                    ) : (
                      <>
                        {termLoan.feesExpected !== undefined ? termLoan.feesExpected : 0}
                        {editServicingFeeDetails && (
                          <Button
                            className="inner-table-custom-button"
                            onClick={() => {
                              setEditingServicingFee(termLoan.instalment);
                              setNewServicingFee(termLoan.feesExpected || '');
                            }}
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </Button>
                        )}
                      </>
                    )}
                  </td>
                  <td>{termLoan.penaltyExpected ? termLoan.penaltyExpected : 0}</td>
                  {/* <td>{termLoan.totalExpected ? termLoan.totalExpected : 0}</td> */}
                  <td className={`${editInstallment && 'edit-highlight '}`}>
                    {editingTotalExpected === termLoan.instalment ? (
                      <>
                        <input
                          type="number"
                          value={newTotalExpected}
                          onChange={e => setNewTotalExpected(e.target.value)}
                          placeholder="Total Expected"
                          className="custom-input-box"
                          min="0"
                        />
                        <Button
                          className="inner-table-custom-button"
                          onClick={() => {
                            setTotalExpectedModalPayload({
                              loanId: termLoan.loanId,
                              instalment: termLoan.instalment,
                              totalExpected: newTotalExpected,
                            });
                            setisTotalExpectedModalOpen(true);
                          }}
                          disabled={!newTotalExpected}
                        >
                          <FontAwesomeIcon icon="save" />
                        </Button>
                        <Button className="inner-table-custom-button" onClick={() => setEditingTotalExpected(null)}>
                          <FontAwesomeIcon icon={faXmark} />
                        </Button>

                        <Modal isOpen={isTotalExpectedModalOpen} onHide={() => setisTotalExpectedModalOpen(false)} centered>
                          {/* <ModalHeader closeButton>
                            <ModalHeader>Confirm Save</ModalHeader>
                          </ModalHeader> */}
                          <ModalBody>
                            <h2>Confirm Save</h2>
                            <div className="contend-heading">Are you sure you want to save the following details?</div>
                            <div className="content-value-text pt-3">
                              <strong>Loan ID:</strong> {totalExpectedModalPayload.loanId}
                            </div>
                            <div className="content-value-text">
                              <strong>Instalment:</strong> {totalExpectedModalPayload.instalment}
                            </div>
                            <div className="content-value-text">
                              <strong>Total Expected:</strong> {totalExpectedModalPayload.totalExpected}
                            </div>
                            <div className="d-flex justify-content-end pt-3">
                              <div className="px-2">
                                <Button className="search-content-btn px-3" onClick={() => setisTotalExpectedModalOpen(false)}>
                                  Cancel
                                </Button>
                              </div>
                              <div className="px-2">
                                <Button
                                  className="generate-report-btn px-4"
                                  onClick={() => {
                                    handleSaveTotalExpected(
                                      totalExpectedModalPayload.loanId,
                                      totalExpectedModalPayload.instalment,
                                      totalExpectedModalPayload.totalExpected,
                                    );
                                    setisTotalExpectedModalOpen(false);
                                  }}
                                >
                                  Submit
                                </Button>
                              </div>
                            </div>
                          </ModalBody>
                        </Modal>
                      </>
                    ) : (
                      <>
                        {termLoan.totalExpected !== undefined ? termLoan.totalExpected : 'N/A'}
                        {editInstallment && (
                          <Button
                            className="inner-table-custom-button"
                            onClick={() => {
                              setEditingTotalExpected(termLoan.instalment);
                              setNewTotalExpected(termLoan.totalExpected);
                            }}
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </Button>
                        )}
                      </>
                    )}
                  </td>
                  <td>{termLoan.principalPaid ? termLoan.principalPaid : 0}</td>
                  <td>{termLoan.interestPaid ? termLoan.interestPaid : 0}</td>
                  <td>{termLoan.feePaid ? termLoan.feePaid : 0}</td>
                  <td>{termLoan.penaltyPaid ? termLoan.penaltyPaid : 0}</td>
                  {/* <td>
                    {editingTotalPaid === termLoan.instalment && editTotalPaymentDetails ? (
                      <>
                        <input
                          type="number"
                          value={newTotalPaid || ''}
                          onChange={e => setNewTotalPaid(e.target.value)}
                          placeholder="Enter Total Paid"
                        />
                        <Button
                          className="inner-table-custom-button"
                          onClick={() => handleSaveTotalPaid(termLoan.loanId, termLoan.instalment, newTotalPaid)}
                          disabled={!newTotalPaid}
                        >
                          <FontAwesomeIcon icon="save" />
                        </Button>
                        <Button
                          className="inner-table-custom-button"
                          onClick={() => {
                            setEditingTotalPaid(null);
                            setNewTotalPaid(null);
                          }}
                        >
                          <strong>X</strong>
                        </Button>
                      </>
                    ) : (
                      <>
                        {termLoan.totalPaid || '0.00'}
                        {editTotalPaymentDetails && termLoan.totalPaid === 0 && termLoan.state !== 'PAID' && (
                          <Button
                            className="inner-table-custom-button"
                            onClick={() => {
                              setEditingTotalPaid(termLoan.instalment);
                              setNewTotalPaid(termLoan.totalPaid ? termLoan.totalPaid.toString() : '');
                            }}
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </Button>
                        )}
                      </>
                    )}
                  </td>  */}
                  <td className={`${editTotalPaymentDetails && 'edit-highlight '}`}>
                    {termLoan.totalPaid || '0.00'}
                    {editTotalPaymentDetails && termLoan.state !== 'PAID' && (
                      <Button
                        className="inner-table-custom-button"
                        onClick={() => handleEditTotalFeeClick(termLoan.loanId, termLoan.instalment, termLoan.totalPaid, termLoan.totalDue)}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </Button>
                    )}
                  </td>

                  <td>{termLoan.paidDateTime ? termLoan.paidDateTime : '00/00/0000'}</td>
                  <td>{termLoan.principalDue ? termLoan.principalDue : 0}</td>
                  <td>{termLoan.interestDue ? termLoan.interestDue : 0}</td>
                  <td>{termLoan.feeDue ? termLoan.feeDue : 0}</td>
                  <td>{termLoan.penaltyDue ? termLoan.penaltyDue : 0}</td>
                  <td>{termLoan.totalDue ? termLoan.totalDue : 0}</td>
                  <td>{termLoan.state ? termLoan.state : 0}</td>
                  <td>{termLoan.typeOfPayment ? termLoan.typeOfPayment : 0}</td>
                  <td className={`${editFeeDetails && 'edit-highlight '}`}>
                    {editingFee === `${termLoan.instalment}-Adhoc Fee` ? (
                      <>
                        <input
                          type="number"
                          value={newFeeAmount}
                          onChange={e => setNewFeeAmount(e.target.value)}
                          placeholder="Ad Hoc Fee"
                          className="custom-input-box"
                          min="0"
                        />
                        <Button
                          className="inner-table-custom-button"
                          onClick={() => {
                            setAdhocModalPayload({
                              loanId: termLoan.loanId,
                              instalment: termLoan.instalment,
                              feeAmount: newFeeAmount,
                              feeType: 'Adhoc Fee',
                            });
                            setIsAdhocConfirmationModalOpen(true);
                          }}
                          disabled={!newFeeAmount}
                        >
                          <FontAwesomeIcon icon="save" />
                        </Button>
                        <Button className="inner-table-custom-button" onClick={() => setEditingFee(null)}>
                          <FontAwesomeIcon icon={faXmark} />
                        </Button>
                        <Modal isOpen={isAdhocConfirmationModalOpen} onHide={() => setIsAdhocConfirmationModalOpen(false)} centered>
                          <ModalBody>
                            <h2>Confirm Save</h2>
                            <div className="contend-heading">
                              Are you sure you want to save the following details For<strong>Adhoc Fee?</strong>
                            </div>
                            <div className="content-value-text pt-3">
                              <strong>Loan ID:</strong> {adhocModalPayload?.loanId}
                            </div>
                            <div className="content-value-text">
                              <strong>Instalment:</strong> {adhocModalPayload?.instalment}
                            </div>
                            <div className="content-value-text">
                              <strong>Fee Amount:</strong> {adhocModalPayload?.feeAmount}
                            </div>
                            <div className="d-flex justify-content-end pt-3">
                              <div className="px-2">
                                <Button className="search-content-btn px-3" onClick={() => setIsAdhocConfirmationModalOpen(false)}>
                                  Cancel
                                </Button>
                              </div>
                              <div className="px-2">
                                <Button
                                  className="generate-report-btn px-4"
                                  onClick={() => {
                                    handleSaveFee(
                                      adhocModalPayload.loanId,
                                      adhocModalPayload.instalment,
                                      adhocModalPayload.feeAmount,
                                      adhocModalPayload.feeType,
                                    );
                                    setIsAdhocConfirmationModalOpen(false);
                                  }}
                                >
                                  Submit
                                </Button>
                              </div>
                            </div>
                          </ModalBody>
                        </Modal>
                      </>
                    ) : (
                      <>
                        {termLoan.adHocFee !== undefined ? termLoan.adHocFee : '0'}
                        {editFeeDetails && (
                          <Button
                            className="inner-table-custom-button"
                            onClick={() => {
                              setEditingFee(`${termLoan.instalment}-Adhoc Fee`);
                              setNewFeeAmount(termLoan.adHocFee);
                            }}
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </Button>
                        )}
                      </>
                    )}
                  </td>
                  <td className={`${editFeeDetails && 'edit-highlight '}`}>
                    {editingFee === `${termLoan.instalment}-Legal And Collection Fee` ? (
                      <>
                        <input
                          type="number"
                          value={newFeeAmount}
                          onChange={e => setNewFeeAmount(e.target.value)}
                          placeholder="Legal And Collection Fee"
                          className="custom-input-box"
                          min="0"
                        />
                        <Button
                          className="inner-table-custom-button"
                          onClick={() => {
                            setLegalCollectionModalPayload({
                              loanId: termLoan.loanId,
                              instalment: termLoan.instalment,
                              feeAmount: newFeeAmount,
                              feeType: 'Legal And Collection Fee',
                            });
                            setIsLegalCollectionConfirmationModalOpen(true);
                          }}
                          disabled={!newFeeAmount}
                        >
                          <FontAwesomeIcon icon="save" />
                        </Button>
                        <Button className="inner-table-custom-button" onClick={() => setEditingFee(null)}>
                          <FontAwesomeIcon icon={faXmark} />
                        </Button>
                        <Modal
                          isOpen={isLegalCollectionConfirmationModalOpen}
                          onHide={() => setIsLegalCollectionConfirmationModalOpen(false)}
                          centered
                        >
                          <ModalBody>
                            <h2>Confirm Save</h2>
                            <div className="contend-heading">
                              Are you sure you want to save the following details for <strong>Legal Collection Fee</strong> ?
                            </div>
                            <div className="content-value-text pt-3">
                              <strong>Loan ID:</strong> {legalCollectionModalPayload?.loanId}
                            </div>
                            <div className="content-value-text">
                              <strong>Instalment:</strong> {legalCollectionModalPayload?.instalment}
                            </div>
                            <div className="content-value-text">
                              <strong>Fee Amount:</strong> {legalCollectionModalPayload?.feeAmount}
                            </div>
                            <div className="d-flex justify-content-end pt-3">
                              <div className="px-2">
                                <Button
                                  className="search-content-btn px-3"
                                  onClick={() => setIsLegalCollectionConfirmationModalOpen(false)}
                                >
                                  Cancel
                                </Button>
                              </div>
                              <div className="px-2">
                                <Button
                                  className="generate-report-btn px-4"
                                  onClick={() => {
                                    handleSaveFee(
                                      legalCollectionModalPayload.loanId,
                                      legalCollectionModalPayload.instalment,
                                      legalCollectionModalPayload.feeAmount,
                                      legalCollectionModalPayload.feeType,
                                    );
                                    setIsLegalCollectionConfirmationModalOpen(false);
                                  }}
                                >
                                  Submit
                                </Button>
                              </div>
                            </div>
                          </ModalBody>
                        </Modal>
                      </>
                    ) : (
                      <>
                        {termLoan.legalCollectionFee !== undefined ? termLoan.legalCollectionFee : 'N/A'}
                        {editFeeDetails && (
                          <Button
                            className="inner-table-custom-button"
                            onClick={() => {
                              setEditingFee(`${termLoan.instalment}-Legal And Collection Fee`);
                              setNewFeeAmount(termLoan.legalCollectionFee);
                            }}
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </Button>
                        )}
                      </>
                    )}
                  </td>

                  <td className={`${editFeeDetails && 'edit-highlight '}`}>
                    {editingFee === `${termLoan.instalment}-Exit Fee` ? (
                      <>
                        <input
                          type="number"
                          value={newFeeAmount}
                          onChange={e => setNewFeeAmount(e.target.value)}
                          placeholder="Exit Fee"
                          className="custom-input-box"
                          min="0"
                        />
                        <Button
                          className="inner-table-custom-button"
                          onClick={() => {
                            setExitFeeModalPayload({
                              loanId: termLoan.loanId,
                              instalment: termLoan.instalment,
                              feeAmount: newFeeAmount,
                              feeType: 'Exit Fee',
                            });
                            setIsExitFeeConfirmationModalOpen(true);
                          }}
                          disabled={!newFeeAmount}
                        >
                          <FontAwesomeIcon icon="save" />
                        </Button>
                        <Button className="inner-table-custom-button" onClick={() => setEditingFee(null)}>
                          <FontAwesomeIcon icon={faXmark} />
                        </Button>
                        <Modal isOpen={isExitFeeConfirmationModalOpen} onHide={() => setIsExitFeeConfirmationModalOpen(false)} centered>
                          <ModalBody>
                            <h2>Confirm Save</h2>
                            <div className="contend-heading">
                              Are you sure you want to save the following details for <strong>Exit Fee</strong>?
                            </div>
                            <div className="content-value-text pt-3">
                              <strong>Loan ID:</strong> {exitFeeModalPayload?.loanId}
                            </div>
                            <div className="content-value-text">
                              <strong>Instalment:</strong> {exitFeeModalPayload?.instalment}
                            </div>
                            <div className="content-value-text">
                              <strong>Fee Amount:</strong> {exitFeeModalPayload?.feeAmount}
                            </div>
                            <div className="d-flex justify-content-end pt-3">
                              <div className="px-2">
                                <Button className="search-content-btn px-3" onClick={() => setIsExitFeeConfirmationModalOpen(false)}>
                                  Cancel
                                </Button>
                              </div>
                              <div className="px-2">
                                <Button
                                  className="generate-report-btn px-4"
                                  onClick={() => {
                                    handleSaveFee(
                                      exitFeeModalPayload.loanId,
                                      exitFeeModalPayload.instalment,
                                      exitFeeModalPayload.feeAmount,
                                      exitFeeModalPayload.feeType,
                                    );
                                    setIsExitFeeConfirmationModalOpen(false);
                                  }}
                                >
                                  Submit
                                </Button>
                              </div>
                            </div>
                          </ModalBody>
                        </Modal>
                      </>
                    ) : (
                      <>
                        {termLoan.exitFee !== undefined ? termLoan.exitFee : 'N/A'}
                        {editFeeDetails && (
                          <Button
                            className="inner-table-custom-button"
                            onClick={() => {
                              setEditingFee(`${termLoan.instalment}-Exit Fee`);
                              setNewFeeAmount(termLoan.exitFee);
                            }}
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </Button>
                        )}
                      </>
                    )}
                  </td>
                  <td>{termLoan.expectedInstalmentDate ? termLoan.expectedInstalmentDate : 0}</td>
                  <td>{termLoan.interestDays ? termLoan.interestDays : 0}</td>
                  <td>{termLoan.billingDaysAdjustment ? termLoan.billingDaysAdjustment : 0}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      {reverseRepaymentDisplay && showSecondTable && reverseRepaymentList.length > 0 ? (
        <div>
          <div className="d-flex justify-content-end">
            <Button className="generate-report-btn ms-2 mt-4 mb-4" onClick={handleReverseRepaymentApply}>
              Reverse Repayment
            </Button>
          </div>
          <div className="mainContainer table-wrapper custom-table-responsive">
            <Table responsive className="table-striped table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Loan ID</th>
                  <th>Action</th>
                  <th>Installment</th>
                  <th>Repaid Amount</th>
                  <th>Principal Balance Opening</th>
                  <th>Principal Balance Closing</th>
                  <th>Interest Days</th>
                  <th>Principal Paid</th>
                  <th>Interest Paid</th>
                  <th>Fee Paid</th>
                  <th>Penalty Paid</th>
                  <th>Total Paid</th>
                  <th>Principal Due</th>
                  <th>Interest Due</th>
                  <th>Fee Due</th>
                  <th>Penalty Due</th>
                  <th>Total Due</th>
                  <th>State</th>
                  <th>Type of Payment</th>
                  <th>Lender</th>
                  <th>Borrower</th>
                  <th>Loan Status</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {(Array.isArray(reverseRepaymentList) ? reverseRepaymentList : []).map((item, index) => (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.loanId}</td>
                    <td>{item.action}</td>
                    <td>{item.instalment}</td>
                    <td>{item.repaidAmount}</td>
                    <td>{item.principalBalanceOpening}</td>
                    <td>{item.principalBalanceClosing}</td>
                    <td>{item.interestDays}</td>
                    <td>{item.principalPaid}</td>
                    <td>{item.interestPaid}</td>
                    <td>{item.feePaid}</td>
                    <td>{item.penaltyPaid}</td>
                    <td>{item.totalPaid}</td>
                    <td>{item.principalDue}</td>
                    <td>{item.interestDue}</td>
                    <td>{item.feeDue}</td>
                    <td>{item.penaltyDue}</td>
                    <td>{item.totalDue}</td>
                    <td>{item.state}</td>
                    <td>{item.typeOfPayment}</td>
                    <td>{item.lenderBusinessName}</td>
                    <td>{item.borrowerBusinessName}</td>
                    <td>{item.loanStatus}</td>
                    <td>{item.created_at}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      ) : null}

      <Modal isOpen={showRepaymentEditModal} onHide={() => setshowRepaymentEditModal(false)} centered>
        <ModalBody>
          <h2>Apply Repayment</h2>
          <div className="content-value-text">
            Loan ID: <span>{selectedLoan.loanId}</span>
          </div>
          <div className="content-value-text">
            Installment: <span>{selectedLoan.instalment}</span>
          </div>
          <div className="content-value-text">
            Due Amount: <span>{selectedLoan.totalDue}</span>
          </div>
          <div>
            <label className="custom-label">Amount</label>
            <Input type="number" min="0" onChange={e => setNewTotalPaid(e.target.value)} placeholder="Enter Amount" />
          </div>
          <div className="mt-2">
            <label className="custom-label">Posting Date</label>
            <input
              type="date"
              value={moment(postingDate).format('YYYY-MM-DD')}
              onChange={e => setNewPostingDate(e.target.value)}
              className="form-control custom-input-box"
            />
          </div>
          <div className="mt-2">
            <label className="custom-label">Posting Note</label>
            <input
              type="textarea"
              className="form-control custom-input-box"
              value={postingNote || ''}
              onChange={e => setNewpostingNote(e.target.value)}
            />
          </div>
          <div className="d-flex justify-content-end pt-3">
            <div className="px-2">
              <Button className="search-content-btn px-3" onClick={() => setshowRepaymentEditModal(false)}>
                Cancel
              </Button>
            </div>
            <div className="px-2">
              <Button
                className="generate-report-btn px-4"
                onClick={() => handleSaveTotalPaid(selectedLoan.loanId, selectedLoan.instalment, newTotalPaid, postingDate, postingNote)}
                disabled={!newTotalPaid}
              >
                Submit
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={showReverseRepaymentModal} onHide={() => setshowReverseRepaymentModal(false)} centered>
        {/* <ModalHeader closeButton>Are you sure you want to reverse this payment?</ModalHeader> */}
        <ModalBody>
          <h2>Are you sure you want to reverse this payment?</h2>
          <div>
            {reverseRepaymentList?.map((item, index) => (
              <div key={item.id || index}>
                <div className="content-value-text">
                  Loan ID: <span>{item.loanId}</span>
                </div>
                <div className="content-value-text">
                  Installment: <span>{item.instalment}</span>
                </div>
                <div className="content-value-text">
                  Repaid Amount: <span>{item.repaidAmount}</span>
                </div>
                <div className="d-flex justify-content-end pt-3">
                  <div className="px-2">
                    <Button className="search-content-btn px-3" onClick={() => setshowReverseRepaymentModal(false)}>
                      Cancel
                    </Button>
                  </div>
                  <div className="px-2">
                    <Button
                      className="generate-report-btn px-4"
                      onClick={() =>
                        handleRepaymentReversalAction({
                          loanId: item.loanId,
                          instalment: item.instalment,
                          repaidAmount: item.repaidAmount,
                        })
                      }
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default TermLoanSchedule;
